// @ts-nocheck
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '@fortawesome/fontawesome-free/css/all.css';
import './assets/styles.css'; // Import the global CSS file
import store from './store'; // Make sure this path is correct



const app = createApp(App);
app.use(router);
app.use(store);
app.mount('#app');
