<template>
  <AppHeader></AppHeader>
  <div class="about-us-container">
    <h1 class="heading">About Us</h1>
    <p>
      Welcome to Yugin, your ultimate cloud-based backup and sharing platform. We make it simple for users to backup their files securely and share experiences effortlessly within private groups. Our unique platform is ideal for adventurers, travelers, and anyone who often captures life's moments in groups.
    </p>
    <p>
      Born from a real-world need during a trekking expedition, our founder realized the challenge of sharing memorable photos and videos amongst participants. Yugin was created to solve this by offering a seamless way to share large files without compromising on privacy and storage.
    </p>
    <p>
      At Yugin, we leverage cutting-edge technology such as face detection to intelligently organize and filter files, enhancing your experience and accessibility. We are committed to evolving continuously; our vision is to transform Yugin into a super app that not only handles your backup needs but also becomes an integral part of your lifestyle adventures.
    </p>
    <p>
      Each group created on our platform uses only the creator's memory allocation, ensuring that sharing memories doesn't cost you your space.
    </p>
    <div class="team-section">
      <h2>Why Choose Us?</h2>
      <ul>
        <li><strong>Secure Backup:</strong> Your files are stored securely and are accessible from anywhere.</li>
        <li><strong>Private Sharing:</strong> Share within groups without impacting other members' storage.</li>
        <li><strong>Innovative Features:</strong> Use face detection to organize and filter content effortlessly.</li>
      </ul>
    </div>
    <div class="contact-info">
      <h2>Contact Us</h2>
      <p>If you have any questions or need more information, please feel free to contact us at:</p>
      <p>Email: <a href="mailto:support@yugin.co">support@yugin.co</a></p>
    </div>
  </div>
  <AppFooter></AppFooter>
</template>

<script>
import AppHeader from '@/components/constant/AppHeader.vue'
import AppFooter from '@/components/constant/AppFooter.vue';

export default {
  name: 'AboutUsPage',
  components: {
    AppHeader,
    AppFooter
  }
}
</script>

<style scoped>
.about-us-container {
  max-width: 800px;
  margin: 50px auto;
  padding: 70px;
  text-align: left;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.heading {
  text-align: center;
}

.team-section, .contact-info {
  margin-top: 40px;
}

.team-section ul {
  list-style-type: none;
  padding: 0;
}

.team-section li {
  margin: 10px 0;
  text-align: left;
}

.contact-info p {
  margin: 5px 0;
}

.contact-info a {
  color: #007BFF;
}

a:hover {
  text-decoration: underline;
}

.message, .error {
  font-size: 18px;
  padding: 10px;
}

.message {
  color: green;
}

.error {
  color: red;
}
</style>
