import axios from 'axios';
import { getValidAuthToken } from '../components/utils/auth'; // Ensure you have an auth utility to get a valid auth token

// Helper function to get the CSRF token value from cookies
function getCsrfToken() {
  let csrfToken = null;
  const cookies = document.cookie.split(';');
  for (let cookie of cookies) {
    let [key, value] = cookie.trim().split('=');
    if (key === 'csrftoken') {
      csrfToken = value;
      break;
    }
  }
  return csrfToken;
}

const axiosInstance = axios.create({
  baseURL: 'https://api.yugin.co', // Adjust this to match your API domain
  headers: {
    'X-Requested-With': 'XMLHttpRequest', // Recommended to configure Django correctly
  },
  withCredentials: true, // This is crucial if your API and client are on different domains
});

// Attach CSRF token to every request
axiosInstance.interceptors.request.use(async (config) => {
  const csrfToken = getCsrfToken();
  config.headers['X-CSRFToken'] = csrfToken;

  // Check if the request requires authentication
  if (config.headers.requiresAuth) {
    const authToken = await getValidAuthToken();

    if (!authToken) {
      window.location.href = `/login?next=${encodeURIComponent(window.location.pathname + window.location.search)}`;
      return Promise.reject(new Error('Authorization required'));
    }

    config.headers['Authorization'] = `Bearer ${authToken}`;
    delete config.headers.requiresAuth; // Remove custom header after use
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

export default axiosInstance;
