<template>
  <div class="app-container">
    <div class="main-content">
      <Sidebar currentPage="editProfile"/>
      <div class="content-wrapper">
        <TopBar pageTitle="Profile"/>
        <main class="content">
          <div class="edit-profile">
            <div class="profile-header">
              <h1>Edit Profile</h1>
              <div class="tabs">
                <button :class="{ active: activeTab === 'profile' }" @click="activeTab = 'profile'">Profile</button>
                <button :class="{ active: activeTab === 'subscription' }" @click="activeTab = 'subscription'">Subscription</button>
              </div>
            </div>

            <div class="form-group">
                <label for="convertToJpeg">Convert Images to JPEG</label>
                <div class="toggle-switch">
                  <input
                    type="checkbox"
                    id="convertToJpeg"
                    v-model="profile.convertToJpeg"
                    @change="toggleConvertToJpeg"
                  />
                  <label for="convertToJpeg" class="toggle-label"></label>
                </div>
                <small>Automatically convert uploaded images to JPEG format in original quality</small>
              </div>
            
            <div v-if="activeTab === 'profile'" class="profile-form">
              <form @submit.prevent="saveChanges">
                <div class="form-group">
                  <label for="avatar">Avatar</label>
                  <div class="avatar-upload">
                    <img v-if="profile.avatar" :src="profile.avatar" alt="Avatar" class="avatar-preview">
                    <button @click="chooseAvatar" type="button" class="choose-avatar-btn">Choose</button>
                    <input type="file" ref="avatarInput" style="display: none" @change="onAvatarSelected" accept="image/jpeg,image/png,image/webp">
                  </div>
                  <small>JPG, JPEG, PNG and WEBP</small>
                </div>

                <div class="form-group">
                  <label for="name">First Name *</label>
                  <input id="name" v-model="profile.first_name" required>
                </div>

                <div class="form-group">
                  <label for="last_name">Last Name *</label>
                  <input id="last_name" v-model="profile.last_name" required>
                </div>

                <div class="form-group">
                  <label for="email">Email</label>
                  <input id="email" v-model="profile.email" type="email" disabled>
                  <small>Save your email from here</small>
                </div>

                <div class="form-group">
                  <label for="phone">Phone Number</label>
                  <input id="phone" ref="phoneInput" type="tel">
                </div>

                <div class="form-group">
                  <label for="address">Address</label>
                  <input id="address" v-model="profile.address">
                </div>

                <div class="form-group">
                  <label for="country">Country *</label>
                  <select id="country" v-model="profile.country" @change="updateStates" required>
                    <option value="">Select a country</option>
                    <option v-for="country in countries" :key="country.isoCode" :value="country.isoCode">
                      {{ country.name }}
                    </option>
                  </select>
                </div>

                <div class="form-group">
                  <label for="state">State *</label>
                  <select id="state" v-model="profile.state" required>
                    <option value="">Select a state</option>
                    <option v-for="state in states" :key="state.isoCode" :value="state.isoCode">
                      {{ state.name }}
                    </option>
                  </select>
                </div>

                <div class="form-group">
                  <label for="city">City</label>
                  <input id="city" v-model="profile.city">
                </div>

                <div class="form-group">
                  <label for="pincode">Pincode</label>
                  <input id="pincode" v-model="profile.pincode">
                </div>

                <button type="submit" class="save-button">Save changes</button>
              </form>
            </div>

            <div v-if="activeTab === 'subscription'" class="subscription-info">
              <h2>Your Subscriptions</h2>
              <div v-if="subscriptions.active.length > 0" class="active-subscriptions">
                <h3>Active Subscriptions</h3>
                <div v-for="sub in subscriptions.active" :key="sub.uuid_id" class="subscription-item">
                  <p>Plan: {{ sub.plan_name }}</p>
                  <p>Status: {{ sub.status }}</p>
                  <p>Start Date: {{ formatDate(sub.start_date) }}</p>
                  <p>End Date: {{ formatDate(sub.end_date) }}</p>
                  <button v-if="sub.is_custom_plan === false" @click="cancelSubscription(sub.uuid_id)" class="cancel-button">Cancel Subscription</button>
                  <small v-if="sub.is_custom_plan === true">Custom Plan can't be cancelled</small>
                </div>
              </div>
              <div v-if="subscriptions.previous.length > 0" class="previous-subscriptions">
                <h3>Previous Subscriptions</h3>
                <div v-for="sub in subscriptions.previous" :key="sub.uuid_id" class="subscription-item">
                  <p>Plan: {{ sub.plan_name }}</p>
                  <p>Status: {{ sub.status }}</p>
                  <p>Start Date: {{ formatDate(sub.start_date) }}</p>
                  <p>End Date: {{ formatDate(sub.end_date) }}</p>
                </div>
              </div>
              <p v-if="subscriptions.active.length === 0 && subscriptions.previous.length === 0">
                You don't have any subscriptions yet.
              </p>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, watch } from 'vue';
import axios from '@/csrf/axios';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import { Country, State } from 'country-state-city';
import Sidebar from './utils/SideBar.vue';
import TopBar from './utils/TopBar.vue';

const activeTab = ref('profile');
const profile = reactive({
  first_name: '',
  last_name: '',
  email: '',
  avatar: '',
  phone_number: '',
  address: '',
  city: '',
  country: '',
  state: '',
  pincode: '',
  storage: {
    plan: '',
    used: '',
    total: ''
  },
  convertToJpeg: true
});

const subscriptions = ref({
  active: [],
  previous: []
});

const avatarInput = ref(null);
const phoneInput = ref(null);
const iti = ref(null);
const countries = ref(Country.getAllCountries());
const states = ref([]);
const originalAvatar = ref('');

const fetchProfileData = async () => {
  try {
    const response = await axios.get('/profile/', {
      headers: { 'requiresAuth': true }
    });
    const response2 = await axios.get('/convert-image/info/', {
      headers: { 'requiresAuth': true }
    });
    const data = response.data;
    Object.assign(profile, {
      first_name: data.user_additional_info.first_name,
      last_name: data.user_additional_info.last_name,
      email: data.user.email,
      avatar: data.user_ai_photo,
      phone_number: data.user_additional_info.phone_number,
      address: data.user_additional_info.address,
      city: data.user_additional_info.city,
      country: data.user_additional_info.country,
      state: data.user_additional_info.state,
      pincode: data.user_additional_info.pincode,
      storage: data.storage,
      convertToJpeg: response2.data.convertToJpeg
    });
    originalAvatar.value = data.user_ai_photo;
    if (iti.value) {
      iti.value.setNumber(profile.phone_number);
    }
    updateStates();
  } catch (error) {
    console.error('Error fetching profile data:', error);
  }
};

const toggleConvertToJpeg = () => {
  axios.post('/convert-image/', { convertToJpeg: profile.convertToJpeg }, {
    headers: { 'requiresAuth': true }
  }).then(response => {
    if (response.data && response.data.message) {
      console.error('Error toggling convert to JPEG:', response.data.message);
    }
  }).catch(error => {
    console.error('Error toggling convert to JPEG:', error);
    // Revert the toggle if the API call fails
    profile.convertToJpeg = !profile.convertToJpeg;
    alert('Failed to update setting. Please try again.');  });
};

const saveChanges = async () => {
  if (!iti.value) {
    console.error('Phone input not initialized properly');
    return;
  }

  const fullNumber = iti.value.getNumber();
  const isValid = iti.value.isValidNumber();

  if (!isValid) {
    alert('Please enter a valid phone number');
    return;
  }

  profile.phone_number = fullNumber;

  const requiredFields = [
    'first_name', 'last_name', 'email', 'phone_number', 'address', 'city', 'country', 'state', 'pincode'
  ];

  const missingFields = requiredFields.filter(field => !profile[field]);

  if (missingFields.length > 0) {
    alert(`Please fill in all required fields: ${missingFields.join(', ')}`);
    return;
  }

  try {
    // Update user details
    await axios.post('/add-user-details/', profile, {
      headers: { 'requiresAuth': true }
    });

    // Upload new avatar if changed
    if (profile.avatar !== originalAvatar.value) {
      const formData = new FormData();
      formData.append('photo', await fetch(profile.avatar).then(r => r.blob()));
      await axios.post('/ai-photo/', formData, {
        headers: { 
          'requiresAuth': true,
          'Content-Type': 'multipart/form-data'
        }
      });
    }

    alert('Profile updated successfully');
  } catch (error) {
    console.error('Error updating profile:', error);
    alert('Failed to update profile');
  }
};

const updateStates = () => {
  if (profile.country) {
    const countryData = countries.value.find(c => c.isoCode === profile.country);
    if (countryData) {
      states.value = State.getStatesOfCountry(countryData.isoCode);
    } else {
      states.value = [];
    }
  } else {
    states.value = [];
  }
};

const chooseAvatar = () => {
  if (avatarInput.value) {
    avatarInput.value.click();
  }
};

const onAvatarSelected = (event) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      profile.avatar = e.target.result;
    };
    reader.readAsDataURL(file);
  }
};

const fetchSubscriptions = async () => {
  try {
    const response = await axios.get('/my-subscription/', {
      headers: { 'requiresAuth': true }
    });
    subscriptions.value = {
      active: response.data.active_subscriptions,
      previous: response.data.previous_subscriptions
    };
  } catch (error) {
    console.error('Error fetching subscriptions:', error);
  }
};

const cancelSubscription = async (uuid_id) => {
  try {
    const response = await axios.post('/cancel/subscription/', { uuid_id }, {
      headers: { 'requiresAuth': true }
    });
    alert(response.data.message);
    await fetchSubscriptions(); // Refresh the subscriptions list
  } catch (error) {
    console.error('Error cancelling subscription:', error);
    alert(error.response.data.error || 'Failed to cancel subscription');
  }
};

const formatDate = (dateString) => {
  return new Date(dateString).toLocaleDateString();
};

watch(() => profile.country, updateStates);

onMounted(() => {
  fetchProfileData();
  iti.value = intlTelInput(phoneInput.value, {
    utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@23.6.0/build/js/utils.js',
    separateDialCode: true,
    initialCountry: 'auto',
    geoIpLookup: function(success) {
      fetch("https://ipapi.co/json")
        .then(res => res.json())
        .then(data => {
          success(data.country_code);
        })
        .catch(() => {
          success("us");
        });
    },
  });
  fetchSubscriptions();
});
</script>

<style scoped>
.app-container {
  display: flex;
  height: 100vh;
  font-family: var(--font-family);
  font-size: var(--font-size);
  color: var(--text-color);
}

.main-content {
  display: flex;
  flex-grow: 1;
}

.content-wrapper {
  flex-grow: 1;
  padding: 1;
}

.edit-profile {
  max-width: 800px;
  margin: 0 auto;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 2rem;
}

.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.profile-header h1 {
  font-size: 24px;
  margin: 0;
}

.tabs {
  display: flex;
}

.tabs button {
  padding: 0.5rem 1rem;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px;
  color: #666;
}

.tabs button.active {
  color: #000;
  border-bottom: 2px solid #000;
}

.subtitle {
  color: #666;
  margin-bottom: 2rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

input, select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.avatar-upload {
  display: flex;
  align-items: center;
}

.avatar-preview {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 1rem;
}

.choose-avatar-btn {
  padding: 0.5rem 1rem;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

small {
  display: block;
  color: #666;
  margin-top: 0.25rem;
}

.save-button {
  padding: 0.75rem 1.5rem;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.save-button:hover {
  background-color: #333;
}

select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: var(--border-radius-small);
}

.subscription-item {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: var(--border-radius-small);
}

.cancel-button {
  padding: 5px 10px;
  background-color: var(--error-color);
  color: var(--accent-color);
  border: none;
  border-radius: var(--border-radius-small);
  cursor: pointer;
}
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-label {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.toggle-label:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .toggle-label {
  background-color: #29b735;
}

input:checked + .toggle-label:before {
  transform: translateX(26px);
}

/* Improve toggle visibility */
.toggle-switch input:focus + .toggle-label {
  box-shadow: 0 0 1px #21f333;
}

/* Optional: Add some spacing */
.form-group {
  margin-bottom: 20px;
}

/* Ensure proper vertical alignment */
.toggle-switch {
  vertical-align: middle;
}

/* Adjust label positioning if needed */
label[for="convertToJpeg"] {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
</style>
