<template>
  <div>
    <AppHeader />
    <main class="login-container" :style="backgroundStyle">
      <div class="login-form card shadow" :class="{ 'loading': isLoading }">
        <h1 class="text-center">Login</h1>
        <!-- Note for Aarya AI users -->
        <p class="text-center mb-3">
          If you have an account on Aarya AI, you can use the same credentials to log in here.
        </p>
        <form @submit.prevent="login" novalidate>
          <div class="mb-3">
            <label for="username" class="form-label">Email or Username</label>
            <input 
              type="text" 
              class="form-control" 
              id="username" 
              v-model.trim="credentials.username" 
              required
              :class="{ 'is-invalid': v$.credentials.username.$error }"
              @blur="v$.credentials.username.$touch()"
            >
            <div class="invalid-feedback" v-if="v$.credentials.username.$error">
              {{ v$.credentials.username.$errors[0].$message }}
            </div>
          </div>
          <div class="mb-3">
            <label for="password" class="form-label">Password</label>
            <div class="password-toggle">
              <input 
                :type="passwordFieldType" 
                class="form-control" 
                id="password" 
                v-model.trim="credentials.password" 
                required
                :class="{ 'is-invalid': v$.credentials.password.$error }"
                @blur="v$.credentials.password.$touch()"
              >
              <button 
                type="button" 
                class="toggle" 
                @click="togglePasswordVisibility"
                :aria-label="showPassword ? 'Hide password' : 'Show password'"
              >
                <i :class="{'fas': true, 'fa-eye': !showPassword, 'fa-eye-slash': showPassword}"></i>
              </button>
            </div>
            <div class="invalid-feedback" v-if="v$.credentials.password.$error">
              {{ v$.credentials.password.$errors[0].$message }}
            </div>
          </div>
          <div v-if="errorMessage" class="alert alert-danger" role="alert">
            {{ errorMessage }}
          </div>
          <div class="mb-3 text-end">
            <router-link to="/forgot-password" class="forgot-password">Forgot Password?</router-link>
          </div>
          <div class="d-grid">
            <button 
              type="submit" 
              class="btn btn-primary" 
              id="login-button"
              :disabled="isLoading"
            >
              <span v-if="!isLoading">Log In</span>
              <span v-else class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </button>
          </div>
        </form>
        <p class="text-center mt-3">
          Don't have an account? <router-link to="/signup" class="signup-link">Sign up</router-link>
        </p>
      </div>
    </main>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email, minLength, helpers } from '@vuelidate/validators'
import AppHeader from '@/components/constant/AppHeader.vue';
import axios from '@/csrf/axios';

const emailOrUsername = helpers.withMessage(
  'Please enter a valid email or username',
  value => {
    // Check if it's a valid email
    if (email.$validator(value)) return true;
    // If not email, check if it's a non-empty string (username)
    return required.$validator(value);
  }
);

export default {
  name: 'LoginPage',
  components: {
    AppHeader
  },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      credentials: {
        username: '',
        password: '',
      },
      showPassword: false,
      errorMessage: '',
      isLoading: false,
      nextUrl: this.$route.query.next || '/my-hub',
      backgroundImages: [
        'https://example.com/path-to-your-image1.jpg',
        'https://example.com/path-to-your-image2.jpg',
        'https://example.com/path-to-your-image3.jpg'
      ]
    };
  },
  computed: {
    backgroundStyle() {
      const randomImage = this.backgroundImages[Math.floor(Math.random() * this.backgroundImages.length)];
      return {
        backgroundImage: `url("${randomImage}")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      };
    },
    passwordFieldType() {
      return this.showPassword ? 'text' : 'password';
    }
  },
  validations() {
    return {
      credentials: {
        username: { emailOrUsername },
        password: { required, minLength: minLength(6) }
      }
    }
  },
  methods: {
    async login() {
      this.errorMessage = '';
      this.v$.$touch();
      if (this.v$.$invalid) return;

      this.isLoading = true;
      try {
        const response = await axios.post('/login/', this.credentials, {
          headers: {
            requiresAuth: false,
          }
        });
        if (response.status === 200 || response.status === 201) {
          localStorage.setItem('isLoggedIn', 'true');
          localStorage.setItem('authToken', response.data.access);
          localStorage.setItem('refreshToken', response.data.refresh);
          const nextUrl = this.$route.query.next ? decodeURIComponent(this.$route.query.next) : '/my-hub';
          this.$router.push(nextUrl);
        }
      } catch (error) {
        console.error(error);
        this.errorMessage = error.response?.data?.error || 'Failed to login. Please try again.';
      } finally {
        this.isLoading = false;
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    }
  }
}
</script>
<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  color: var(--text-color);
  transition: background-image 0.5s ease-in-out;
}

.login-form {
  width: 100%;
  max-width: 400px;
  padding: 2rem;
  border-radius: 0.5rem;
  background: var(--background-color);
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  color: var(--text-color);
  transition: all 0.3s ease;
}

.login-form.loading {
  opacity: 0.7;
  pointer-events: none;
}

.forgot-password, .signup-link {
  color: var(--link-color);
  transition: color 0.3s ease;
}

.forgot-password:hover, .signup-link:hover {
  color: var(--hover-color);
}

.password-toggle {
  position: relative;
}

.password-toggle .toggle {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: var(--primary-color);
}

.fas {
  color: var(--primary-color);
}

.alert {
  color: var(--error-color);
}

#login-button {
  background-color: var(--background-color);
  color: var(--text-color);
  border-color: var(--text-color);
  transition: all 0.3s ease;
}

#login-button:hover:not(:disabled) {
  background-color: var(--text-color);
  color: var(--background-color);
}

#login-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.form-control {
  width: 100%;
  padding: 0.8rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-control:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

@media (max-width: 576px) {
  .login-form {
    padding: 1rem;
    width: 90%;
  }
}
</style>