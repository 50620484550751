<template>
  <div v-if="show" class="progress-indicator">
    <div class="progress-content">
      <div class="progress-bar">
        <div class="progress-fill" :style="{ width: `${progress}%` }"></div>
      </div>
      <div class="progress-text">
        <span class="progress-percentage">{{ Math.round(progress) }}%</span>
        <span class="progress-message">{{ progressText }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressIndicator',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    progress: {
      type: Number,
      required: true
    },
    progressText: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
.progress-indicator {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 400px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  padding: 16px;
  z-index: 1000;
  transition: all 0.3s ease;
}

.progress-content {
  display: flex;
  flex-direction: column;
}

.progress-bar {
  width: 100%;
  height: 8px;
  background: #f0f0f0;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 12px;
}

.progress-fill {
  height: 100%;
  background: linear-gradient(90deg, #4CAF50, #8BC34A);
  border-radius: 4px;
  transition: width 0.3s ease;
}

.progress-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.progress-percentage {
  font-weight: bold;
  color: #4CAF50;
}

.progress-message {
  color: #666;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .progress-indicator {
    width: 95%;
    padding: 12px;
    max-width: 100%;
  }

  .progress-text {
    font-size: 12px;
    flex-direction: column;
    align-items: flex-start;
  }

  .progress-message {
    margin-top: 4px;
  }
}

@media (max-width: 480px) {
  .progress-indicator {
    padding: 8px;
  }

  .progress-text {
    font-size: 11px;
  }

  .progress-bar {
    height: 6px;
  }
}
</style>
