<template>
    <AppHeader></AppHeader>
    <div class="reset-password-container" :style="backgroundStyle">
      <div class="reset-password-form card shadow">
        <h2 class="text-center">Reset Password</h2>
        <!-- Check if the operation was successful to display the message or the form -->
        <div v-if="operationSuccess">
  <p class="text-success text-center">{{ successMessage }} Redirecting in {{ countdown }} seconds...</p>
  <p class=" text-center">If you are not redirected automatically, click below.</p>
  <div class="d-grid">
    <router-link to="/login" class="btn btn-primary">Go to Login</router-link>
  </div>
</div>
        <div v-else>
          <form @submit.prevent="resetPassword">
            <div class="mb-3">
              <label for="password" class="form-label">New Password</label>
              <div class="password-toggle">
                <input :type="passwordFieldType" class="form-control" id="password" v-model="password" required>
                <span class="toggle" @click="togglePasswordVisibility">
                  <i :class="{'fas': true, 'fa-eye': !showPassword, 'fa-eye-slash': showPassword}"></i>
                </span>
              </div>
            </div>
            <div class="mb-3">
              <label for="confirmPassword" class="form-label">Confirm New Password</label>
              <div class="password-toggle">
                <input :type="passwordFieldType" class="form-control" id="confirmPassword" v-model="confirmPassword" required>
                <span class="toggle" @click="toggleConfirmPasswordVisibility">
                  <i :class="{'fas': true, 'fa-eye': !showConfirmPassword, 'fa-eye-slash': showConfirmPassword}"></i>
                </span>
              </div>
            </div>
            <div v-if="errorMessage" class="alert alert-danger">
              {{ errorMessage }}
            </div>
            <div class="d-grid">
              <button type="submit" class="btn btn-primary" id="reset-button">Reset Password</button>
            </div>
          </form>
          <p class="text-center mt-3">
            Remembered your password? <router-link to="/login" class="signin-link">Sign In</router-link>
          </p>
        </div>
      </div>
    </div>
  </template>
    
    <script>
    import AppHeader from '@/components/constant/AppHeader.vue';
    import axios from '@/csrf/axios';
    import { useRouter, useRoute } from 'vue-router';
    
    export default {
      name: 'ResetPasswordPage',
      components: {
        AppHeader
      },
      setup() {
        const router = useRouter();
    const route = useRoute();  // Get access to the route object

    // Extract UID and Token from the URL
    const uid = route.query.uid;
    const token = route.query.token;

    return { router, uid, token };      },
      data() {
        return {
          password: '',
          confirmPassword: '',
          showPassword: false,
          showConfirmPassword: false,
          errorMessage: '',
          countdown: 5,  // Start the countdown from 5 seconds
          successMessage: 'Your password has been reset successfully. You will be redirected to the login page in 5 seconds.',
          operationSuccess: false,
          backgroundImages: [
            'https://example.com/path-to-your-image1.jpg',
            'https://example.com/path-to-your-image2.jpg',
            'https://example.com/path-to-your-image3.jpg'
          ]
        };
      },
      computed: {
        backgroundStyle() {
          const randomImage = this.backgroundImages[Math.floor(Math.random() * this.backgroundImages.length)];
          return {
            backgroundImage: `url("${randomImage}")`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          };
        },
        passwordFieldType() {
          return this.showPassword ? 'text' : 'password';
        }
      },
      methods: {
        async resetPassword() {
          if (!this.password || !this.confirmPassword) {
            this.errorMessage = 'Both fields are required.';
            return;
          }
          if (this.password !== this.confirmPassword) {
            this.errorMessage = 'Passwords do not match.';
            return;
          }
          try {
            const response = await axios.post('/reset-password/', {
              password: this.password,
              uid: this.uid,
              token: this.token
            },
            {
              headers: {
                requiresAuth: false
              }
            });
            if (response.status === 200) {
              this.operationSuccess = true;
              const interval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown -= 1;
        } else {
          clearInterval(interval);
          this.router.push('/login');
        }
      }, 1000);
    }
          } catch (error) {
            this.errorMessage = error.response && error.response.data ? error.response.data.error : 'Failed to reset password.';
          }
        },
        togglePasswordVisibility() {
          this.showPassword = !this.showPassword;
        },
        toggleConfirmPasswordVisibility() {
          this.showConfirmPassword = !this.showConfirmPassword;
        }
      }
    }
    </script>
      
  <style scoped>
  /* Styles are similar to the login and sign-up forms */
  .reset-password-container{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    color : var(--text-color);
  }
  .reset-password-form{
    max-width: 400px;
    padding: 2rem;
    border-radius: 0.5rem;
    background: var(--background-color);
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    color: var(--text-color);
  } 
  .password-toggle {
  display: flex;
  align-items: center;
}
.password-toggle .toggle {
  margin-left: -30px;
  cursor: pointer;
}
  .alert{
    color: var(--error-color);
  } 
  #reset-button {
    background-color: var(--background-color);
    color: var(--text-color);
    border: var(--text-color)
  }

  #reset-button:hover {
    background-color: var(--text-color);
    color: var(--background-color);
    border: var(--text-color)
  }

  .fas {
  color: var(--primary-color); /* adjust as needed */
}

@media (max-width: 576px){
  .reset-password-form{
    padding: 1rem;
  }
}
  </style>
  