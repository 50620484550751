<template>
  <div class="container mt-5">
    <div class="card shadow">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h2>User Files and Folders</h2>
        <button class="btn btn-secondary" @click="logout">Logout</button>
      </div>
      <div class="card-body">
        <div>
          <strong>Username:</strong> {{ user }}
        </div>
        <div class="mt-3">
          <strong>Files:</strong> {{ files.length }}
          <button class="btn btn-primary ms-3" @click="toggleFilesVisibility">
            {{ showFiles ? 'Hide' : 'Show' }} Files
          </button>
        </div>
        <div class="mt-3">
          <strong>Folders:</strong> {{ folders.length }}
          <button class="btn btn-primary ms-3" @click="toggleFoldersVisibility">
            {{ showFolders ? 'Hide' : 'Show' }} Folders
          </button>
        </div>
        <ul class="list-group mt-3" v-if="showFiles">
          <li class="list-group-item" v-for="(file, index) in files" :key="index">
            {{ file }}
          </li>
        </ul>
        <ul class="list-group mt-3" v-if="showFolders">
          <li class="list-group-item" v-for="(folder, index) in folders" :key="index">
            {{ folder }}
          </li>
        </ul>
      </div>
      <div class="card-footer text-end">
        <button class="btn btn-danger" @click="confirmDelete" :disabled="loading || (files.length === 0 && folders.length === 0)">
          Delete All
        </button>
      </div>
    </div>

    <!-- Success Alert -->
    <div v-if="successMessage" class="alert alert-success mt-3">
      {{ successMessage }}
    </div>

    <!-- Error Alert -->
    <div v-if="errorMessage" class="alert alert-danger mt-3">
      {{ errorMessage }}
    </div>

    <!-- Confirmation Modal -->
    <div class="modal fade" id="confirmationModal" tabindex="-1" aria-labelledby="confirmationModalLabel" aria-hidden="true" ref="confirmationModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="confirmationModalLabel">Confirmation</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            Are you sure you want to delete all files and folders?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-danger" @click="deleteAll">Confirm</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Loading Modal -->
    <div class="modal fade" id="loadingModal" tabindex="-1" aria-labelledby="loadingModalLabel" aria-hidden="true" ref="loadingModal">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body text-center">
            Deleting...
            <div class="spinner-border text-primary ms-3" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/csrf/axios'; // Ensure axios is imported to make HTTP requests
import { Modal } from 'bootstrap';

export default {
  data() {
    return {
      user: '',
      files: [],
      folders: [],
      showFiles: false,
      showFolders: false,
      dialog: false,
      loading: false,
      successMessage: '',
      errorMessage: ''
    };
  },
  methods: {
    async fetchData() {
      try {
        const response = await axios.get('/users/files-folders/', {
          headers: {
            requiresAuth: true
          }
        });
        this.user = response.data.user;
        this.files = response.data.files;
        this.folders = response.data.folders;
      } catch (error) {
        this.errorMessage = error.response ? error.response.data.detail : 'Failed to fetch data.';
      }
    },
    toggleFilesVisibility() {
      this.showFiles = !this.showFiles;
    },
    toggleFoldersVisibility() {
      this.showFolders = !this.showFolders;
    },
    confirmDelete() {
      const modal = new Modal(this.$refs.confirmationModal);
      modal.show();
    },
    async deleteAll() {
      const confirmationModal = new Modal(this.$refs.confirmationModal);
      confirmationModal.hide();

      const loadingModal = new Modal(this.$refs.loadingModal);
      loadingModal.show();

      this.loading = true;
      try {
        await axios.delete('/users/delete-files-folders/', {
          headers: {
requiresAuth: true,         }
        });
        this.loading = false;
        loadingModal.hide();
        this.successMessage = 'All files and folders deleted successfully.';
        this.fetchData(); // Refresh the data after deletion
      } catch (error) {
        this.loading = false;
        loadingModal.hide();
        this.errorMessage = error.response ? error.response.data.detail : 'Failed to delete data.';
      }
    },
    logout() {
      localStorage.removeItem('authToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('isLoggedIn');
      this.$router.push({ name: 'Login' });
    }
  },
  mounted() {
    this.fetchData();
  }
};
</script>

<style scoped>
.card {
  margin: 20px;
}
</style>
