<template>
  <div>
    <AppHeader />
    <div class="subscription-container" :style="backgroundStyle">
      <div class="subscription-management card shadow">
        <h2 class="text-center mb-4">Choose Your Plan</h2>

        <div v-if="loading" class="text-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>

        <div v-else-if="error" class="alert alert-danger" role="alert">
          {{ error }}
        </div>

        <div v-else>
          <p class="text-center mb-4">
            Our plans offer flexible storage options, Aarya AI assistant, and dedicated support to enhance your productivity.
          </p>

          <!-- Toggle Switch for Aarya AI -->
          <div class="text-center mb-4">
            <label class="switch">
              <input type="checkbox" v-model="includeAaryaAI">
              <span class="slider round"></span>
            </label>
            <span class="ml-2">
              {{ includeAaryaAI ? " Showing plans with Aarya AI" : " Showing plans without Aarya AI" }}
            </span>
          </div>

          <!-- Plan Comparison Table -->
          <div class="table-responsive mb-4">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Feature</th>
                  <th v-for="plan in filteredPlans" :key="plan.id">{{ plan.name }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Storage</td>
                  <td v-for="plan in filteredPlans" :key="plan.id">{{ plan.storage }}</td>
                </tr>
                <tr>
                  <td>Group Members</td>
                  <td v-for="plan in filteredPlans" :key="plan.id">{{ plan.groupMembers }}</td>
                </tr>
                <tr>
                  <td>AI Face Search</td>
                  <td v-for="plan in filteredPlans" :key="plan.id">{{ plan.aiPhotoProcessing }}</td>
                </tr>
                <tr>
                  <td>Aarya AI Assistant</td>
                  <td v-for="plan in filteredPlans" :key="plan.id">
                    <div v-if="plan.includeAaryaAI">
                      Included
                      <i class="fas fa-info-circle" @click="showAaryaAIInfo(plan.id)"></i>
                    </div>
                    <div v-else>
                      Not Included
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Support</td>
                  <td v-for="plan in filteredPlans" :key="plan.id">{{ plan.support }}</td>
                </tr>
                <tr>
                  <td>Monthly Price</td>
                  <td v-for="plan in filteredPlans" :key="plan.id">
                    ₹{{ plan.monthlyPrice }}<br>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td v-for="plan in filteredPlans" :key="plan.id">
                    <button v-if="plan.id !== 0" @click="selectPlan(plan)" class="btn btn-primary btn-sm">Select</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Custom Plan Builder -->
          <div class="custom-plan-builder card mb-4">
            <div class="card-body">
              <h3 class="card-title">Build Your Custom Plan</h3>
              <div class="form-group">
                <label for="storage-slider">Storage: {{ customPlan.storage }}GB</label>
                <input type="range" class="form-range" id="storage-slider" v-model="customPlan.storage" min="5" max="2000" step="1" @input="calculateCustomPrice">
              </div>
              <div class="form-group">
                <label for="duration-slider">Duration: {{ customPlan.duration }} days</label>
                <input type="range" class="form-range" id="duration-slider" v-model="customPlan.duration" min="5" max="365" step="1" @input="calculateCustomPrice">
              </div>
              <div class="form-group">
                <label for="members-slider">Group Members: {{ customPlan.group_member }}</label>
                <input type="range" class="form-range" id="members-slider" v-model="customPlan.group_member" min="12" max="100" step="1" @input="calculateCustomPrice">
              </div>
              <p class="mt-3">Estimated Price: ₹{{ customPlan.price }}</p>
              <button @click="confirmCustomPlan" class="btn btn-primary">Create Custom Plan</button>
            </div>
          </div>

          <!-- Terms and Conditions agreement -->
          <div class="form-check mb-3">
            <input class="form-check-input" type="checkbox" v-model="agreedToTerms" id="termsCheck">
            <label class="form-check-label" for="termsCheck">
              I agree to the <a href="/terms-and-conditions" target="_blank">Terms and Conditions</a> and <a href="/privacy" target="_blank">Privacy Policy</a>
            </label>
          </div>

          <div class="payment-notice">
            <p>Subscription plans will be charged monthly. Custom plans are one-time payments.</p>
          </div>
        </div>

        <!-- Aarya AI Info Modal -->
          <div v-if="showAaryaAIDetailsModal" class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Aarya AI Features</h5>
                  <button type="button" class="close" @click="closeAaryaAIDetailsModal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p>{{ selectedAaryaAIDetails }}</p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" @click="closeAaryaAIDetailsModal">Close</button>
                </div>
              </div>
            </div>
          </div>

        <!-- Checkout Dialog -->
        <div v-if="showCheckoutDialog" class="modal" tabindex="-1" role="dialog">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Checkout</h5>
                <button type="button" class="close" @click="closeCheckoutDialog" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h6>Selected Plan: {{ selectedPlan.name }}</h6>
                <p>Price: ₹{{ selectedPlan.price }}</p>
                <p v-if="!selectedPlan.isCustom">Duration: {{ selectedPlan.duration_days }} days</p>
                <p v-if="selectedPlan.isCustom">Duration: {{ customPlan.duration }} days</p>
                <p v-if="selectedPlan.isCustom" class="text-info">This is a one-time payment plan.</p>
                <p>{{ selectedPlan.description }}</p>
                <button @click="confirmPurchase" class="btn btn-success" :disabled="!agreedToTerms">
                  Confirm Purchase
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- User Details Dialog -->
        <div v-if="showUserDetailsDialog" class="modal" tabindex="-1" role="dialog">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Complete Your Profile</h5>
                <button type="button" class="close" @click="showUserDetailsDialog = false" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form @submit.prevent="addUserDetails">
                  <div class="form-group">
                    <label for="first_name">First Name</label>
                    <input type="text" id="first_name" v-model="userDetails.first_name" class="form-control" required>
                  </div>
                  <div class="form-group">
                    <label for="last_name">Last Name</label>
                    <input type="text" id="last_name" v-model="userDetails.last_name" class="form-control" required>
                  </div>
                  <div class="form-group">
                    <label for="phone">Phone Number</label>
                    <input type="tel" id="phone" class="form-control" required>
                  </div>
                  <div class="form-group">
                    <label for="address">Address</label>
                    <input type="text" id="address" v-model="userDetails.address" class="form-control" required>
                  </div>
                  <div class="form-group">
                    <label for="country">Country</label>
                    <select id="country" v-model="userDetails.country" class="form-control" required>
                      <option value="">Select Country</option>
                      <option v-for="country in countries" :key="country.isoCode" :value="country.name">
                        {{ country.name }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="state">State</label>
                    <select id="state" v-model="userDetails.state" class="form-control" required>
                      <option value="">Select State</option>
                      <option v-for="state in states" :key="state.isoCode" :value="state.name">
                        {{ state.name }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="city">City</label>
                    <input type="text" id="city" v-model="userDetails.city" class="form-control" required>
                  </div>
                  <div class="form-group">
                    <label for="pincode">Pincode</label>
                    <input type="text" id="pincode" v-model="userDetails.pincode" class="form-control" required>
                  </div>
                  <button type="submit" class="btn btn-primary">Update Details</button>
                </form>
              </div>
            </div>
          </div>
        </div>

        <!-- Login Modal -->
        <div v-if="showLoginModal" class="modal" tabindex="-1" role="dialog">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Login Required</h5>
                <button type="button" class="close" @click="showLoginModal = false" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p>You need to be logged in to create a custom plan.</p>
                <button @click="redirectToLogin" class="btn btn-primary">Go to Login</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

     <!-- Loading Screen -->
  <div v-if="isVerifyingPayment" class="loading-overlay">
    <div class="loading-content">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <p class="mt-3">Verifying payment...</p>
    </div>
  </div>

  <!-- Payment Success Dialog -->
  <div v-if="showPaymentSuccessDialog" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Payment Successful</h5>
        </div>
        <div class="modal-body">
          <p>Your payment was successful! Your plan is now active.</p>
          <p>You will be redirected to My Hub in {{ redirectCountdown }} seconds.</p>
        </div>
      </div>
    </div>
  </div>

    <AppFooter />
  </div>
</template>

<script>
import AppHeader from "./constant/AppHeader.vue";
import AppFooter from "./constant/AppFooter.vue";
import axios from "@/csrf/axios";
import { getValidAuthToken } from "../components/utils/auth";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";
import { Country, State } from "country-state-city";

export default {
  name: "SubscriptionManagement",
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      showUserDetailsDialog: false,
      userDetails: {
        phone_number: "",
        address: "",
        city: "",
        country: "",
        state: "",
        pincode: "",
        first_name: "",
        last_name: "",
      },
      phoneInput: "", // Ensure phoneInput is defined
      iti: null, // IntlTelInput instance
      countries: Country.getAllCountries(),
      states: [],
      cities: [],
      availablePlans: [
        {
          id: 2,
          name: "Free Plan (with Aarya AI)",
          storage: "2GB",
          groupMembers: "10",
          aiPhotoProcessing: "Limited",
          includeAaryaAI: true,
          support: "Email",
          monthlyPrice: 0,
          yearlyPrice: null,
          features: [
            "5GB Storage",
            "5 group members",
            "Limited AI photo processing",
            "Community Support",
          ],
          description: "A great start to experience Yugin.",
        },
        {
          id: 3,
          name: "Basic Plan (without Aarya AI)",
          storage: "75GB",
          groupMembers: "25",
          aiPhotoProcessing: "300/day",
          includeAaryaAI: false,
          support: "Email",
          monthlyPrice: 99,
          yearlyPrice: 999,
          features: [
            "75GB Storage",
            "25 group members",
            "Face search 300 photos per day",
            "Basic Support",
          ],
          description: "Great for personal use with sufficient storage and features.",
        },
        {
          id: 11,
          name: "Basic Plan (with Aarya AI)",
          storage: "75GB",
          groupMembers: "25",
          aiPhotoProcessing: "300/day",
          includeAaryaAI: true,
          support: "Email",
          monthlyPrice: 599,
          yearlyPrice: 7188,
          features: [
            "75GB Storage",
            "25 group members",
            "Face search 1000 photos per day",
            "Basic Support",
            "Aarya AI Assistant with rate limits",
          ],
          description: "Great for personal use with added AI features.",
        },
        {
          id: 8,
          name: "Premium Plan (without Aarya AI)",
          storage: "200GB",
          groupMembers: "50",
          aiPhotoProcessing: "10000/day",
          includeAaryaAI: false,
          support: "Priority",
          monthlyPrice: 249,
          yearlyPrice: 2499,
          features: [
            "200GB Storage",
            "50 group members",
            "Face search 10000 photos per day",
            "Priority Support",
            "Access to All Features",
            "Access to New Features",
          ],
          description: "Perfect for professionals needing more power and support.",
        },
        {
          id: 13,
          name: "Premium Plan (with Aarya AI)",
          storage: "200GB",
          groupMembers: "50",
          aiPhotoProcessing: "10000/day",
          includeAaryaAI: true,
          support: "Priority",
          monthlyPrice: 899,
          yearlyPrice: 10788,
          features: [
            "200GB Storage",
            "50 group members",
            "Face search 10000 photos per day",
            "Priority Support",
            "Access to All Features",
            "Access to New Features",
            "Aarya AI Assistant with rate limits",
          ],
          description: "Perfect for professionals with advanced AI needs.",
        },
        {
          id: 7,
          name: "Pro Plan (without Aarya AI)",
          storage: "2TB",
          groupMembers: "Unlimited",
          aiPhotoProcessing: "Unlimited",
          includeAaryaAI: false,
          support: "24/7",
          monthlyPrice: 999,
          yearlyPrice: 9999,
          features: [
            "2TB Storage",
            "Unlimited group members",
            "Unlimited photos",
            "24/7 Support",
            "Access to All Features",
          ],
          description: "Best for large teams and enterprises.",
        },
        {
          id: 12,
          name: "Pro Plan (with Aarya AI)",
          storage: "2TB",
          groupMembers: "Unlimited",
          aiPhotoProcessing: "Unlimited",
          includeAaryaAI: true,
          support: "24/7",
          monthlyPrice: 1899,
          yearlyPrice: 22788,
          features: [
            "2TB Storage",
            "Unlimited group members",
            "Unlimited photos",
            "24/7 Support",
            "Access to All Features",
            "Aarya AI Assistant with unlimited usage",
          ],
          description: "Best for large teams and enterprises needing AI capabilities.",
        },
      ],
      customPlan: {
        storage: 5,
        duration: 5,
        group_member: 12,
        price: 21,
      },
      includeAaryaAI: true, // Toggle state for Aarya AI inclusion
      loading: false,
      error: null,
      showCheckoutDialog: false,
      showThankYouDialog: false,
      agreedToTerms: true,
      isCustomPlan: false,
      selectedPlan: null,
      showCustomPlan: false,
      showLoginModal: false,
      razorpayScriptLoaded: false,
      backgroundImages: [],
       isVerifyingPayment: false,
      showPaymentSuccessDialog: false,
      redirectCountdown: 3,
      faqs: [
        {
          question: "How does the free trial work?",
          answer:
            "You can try our premium features for 7 days. No credit card required.",
        },
        {
          question: "Can I switch plans later?",
          answer:
            "Yes, you can upgrade or downgrade your plan at any time. Changes take effect in the next billing cycle.",
        },
        {
          question: "What payment methods do you accept?",
          answer: "We accept major credit cards, PayPal, and more.",
        },
      ],
      testimonials: [
        {
          quote:
            "Yugin's Pro Plan has transformed how we collaborate as a team!",
          author: "John Doe, CEO of XYZ Inc.",
        },
        {
          quote: "The custom plan option was perfect for our unique needs.",
          author: "Jane Smith, Freelance Designer",
        },
      ],
       showAaryaAIDetailsModal: false,
    selectedAaryaAIDetails: null,
    aaryaAIDetails: {
      2: "Enjoy basic Aarya AI features with limited usage, including 5 web searches per day and the ability to upload 1 PDF document.",
  11: "Access the Aarya AI Assistant with rate limits, featuring an intelligent model, 25 web searches per day, and the ability to upload a total of 5 PDF documents.",
  13: "Experience advanced Aarya AI features with higher rate limits, including complex queries, an intelligent model, 50 web searches per day, and the ability to upload up to 5 PDF documents per conversation.",
  12: "Benefit from unlimited Aarya AI usage with an intelligent model, 75 web searches per day, and the ability to upload up to 10 PDF documents per conversation."
},
    };
  },
  computed: {
    backgroundStyle() {
      const randomImage =
        this.backgroundImages[
          Math.floor(Math.random() * this.backgroundImages.length)
        ];
      return {
        backgroundImage: `url("${randomImage}")`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      };
    },
    filteredPlans() {
      return this.availablePlans.filter(
        (plan) =>
          plan.includeAaryaAI === this.includeAaryaAI || plan.id === 1
      );
    },
  },

  mounted() {
    try {
      this.loadRazorpay();
    } catch (error) {
      console.error("Failed to load Razorpay:", error);
      this.error =
        "Failed to initialize payment gateway. Please refresh the page and try again.";
    }
  },

  methods: {
    showAaryaAIInfo(planId) {
    this.selectedAaryaAIDetails = this.aaryaAIDetails[planId];
    this.showAaryaAIDetailsModal = true;
  },
  
  closeAaryaAIDetailsModal() {
    console.log("Modal closed"); // Log when modal is closed
    this.showAaryaAIDetailsModal = false;
  },
    initializePhoneInput() {
      const input = document.querySelector("#phone");
      if (!input) {
        console.error("Phone input element not found");
        return;
      }

      if (this.iti) {
        this.iti.destroy();
      }

      this.iti = intlTelInput(input, {
        utilsScript:
          "https://cdn.jsdelivr.net/npm/intl-tel-input@23.6.0/build/js/utils.js",
        separateDialCode: true,
        initialCountry: "auto",
        geoIpLookup: function (success) {
          fetch("https://ipapi.co/json")
            .then((res) => res.json())
            .then((data) => {
              success(data.country_code);
            })
            .catch(() => {
              success("us");
            });
        },
      });

      input.addEventListener("input", this.updatePhoneNumber);
      input.addEventListener("countrychange", this.updatePhoneNumber);

    },
    updatePhoneNumber() {
      if (this.iti) {
        const input = document.querySelector("#phone");
        if (input) {
          this.phoneInput = input.value;

          // Get the full number with country code
          const fullNumber = this.iti.getNumber();

          // Check if the number is valid
          const isValid = this.iti.isValidNumber(fullNumber);

          if (isValid) {
            this.error = "";
          } else {
            this.error = "Please enter a valid phone number";
          }
        }
      }
    },
    loadRazorpay() {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        script.onload = () => {
          if (typeof window.Razorpay === "function") {
            this.razorpayScriptLoaded = true;
            resolve();
          } else {
            console.error("Razorpay SDK not loaded properly");
            reject(new Error("Razorpay SDK not loaded properly"));
          }
        };
        script.onerror = () => {
          console.error("Failed to load Razorpay script");
          this.error =
            "Failed to load payment gateway. Please try again later.";
          reject(new Error("Failed to load Razorpay script"));
        };
        document.body.appendChild(script);
      });
    },
    toggleCustomPlan() {
      this.showCustomPlan = !this.showCustomPlan;
      if (this.showCustomPlan) {
        this.calculateCustomPrice();
      }
    },

    async checkAuthStatus() {
      const token = await getValidAuthToken();
      this.isLoggedIn = !!token;
    },

    calculateCustomPrice() {
      // This is a simple example. Adjust the calculation based on your pricing model
      const basePrice = 100;
      const storagePrice = this.customPlan.storage * 0.75; // 2 per GB
      const memberPrice = Math.max(0, this.customPlan.group_member - 10) * 10; // 10 per additional member over 10
      const durationFactor = this.customPlan.duration / 30; // price increases with duration
      this.customPlan.price = Math.round(
        (basePrice + storagePrice + memberPrice) * durationFactor
      );
    },
    async confirmCustomPlan() {
  if (!this.validateCustomPlan()) {
    return;
  }
  this.selectedPlan = {
    ...this.customPlan,
    isCustom: true,
  };
  this.isCustomPlan = true; // Add this line
  this.showCheckoutDialog = true;
},

    validateCustomPlan() {
      // Add your custom validation logic here
      if (
        this.customPlan.storage <= 0 ||
        this.customPlan.duration <= 0 ||
        this.customPlan.price <= 0 ||
        this.customPlan.group_member < 10
      ) {
        this.error = "Please enter valid values for all fields";
        return false;
      }
      return true;
    },

    async checkUserDetails() {
      try {
        const response = await axios.get("/check-user-details/", {
          headers: { requiresAuth: true },
        });
        this.userDetails = response.data;
        this.updateStates();
        return true;
      } catch (error) {
        if (error.response && error.response.status === 400) {
          this.showUserDetailsDialog = true;
          return false;
        }
        console.error("Error checking user details:", error);
        this.error = "Failed to check user details. Please try again.";
        return false;
      }
    },

    async addUserDetails() {
      if (!this.iti) {
        console.error("Phone input not initialized properly");
        this.error = "Phone input not initialized properly";
        return;
      }

      const input = document.querySelector("#phone");
      const phoneNumber = input ? input.value : "";
      const fullNumber = this.iti.getNumber();
      const isValid = this.iti.isValidNumber();

      if (!phoneNumber) {
        this.error = "";
        return;
      }


      if (!isValid) {
        this.error = "Please enter a valid phone number";
        return;
      }

      this.userDetails.phone_number = fullNumber;

      try {
        this.loading = true;
        const response = await axios.post(
          "/add-user-details/",
          this.userDetails,
          {
            headers: { requiresAuth: true },
          }
        );
        if (!response.data) {
          this.error = "Failed to update user details. Please try again.";
          return;
        }
        this.showUserDetailsDialog = false;
        this.confirmPurchase();
      } catch (error) {
        console.error("Error adding user details:", error);
        this.error = "Failed to update user details. Please try again.";
      } finally {
        this.loading = false;
      }
    },

    beforeUnmount() {
      if (this.iti) {
        this.iti.destroy();
      }
    },

    updateStates() {
      if (this.userDetails.country) {
        const countryData = this.countries.find(
          (c) => c.name === this.userDetails.country
        );
        if (countryData) {
          this.states = State.getStatesOfCountry(countryData.isoCode);
        }
      }
    },
    async confirmPurchase() {
      if (!this.selectedPlan) {
        this.error = "Please select a plan before proceeding";
        return;
      }

      await this.checkAuthStatus();
      if (!this.isLoggedIn) {
        this.showLoginModal = true;
        return;
      }

      const userDetailsValid = await this.checkUserDetails();
      if (!userDetailsValid) {
        return;
      }

      this.closeCheckoutDialog();
      this.loading = true;
      this.error = null;
      console.log(this.iscustomPlan);
      try {
        if (this.isCustomPlan === false) {
          const response = await axios.post(
            "/subscribe/",
            {
              plan_id: this.selectedPlan.id,
            },
            {
              headers: { requiresAuth: true },
            }
          );
          this.handleSubscription(response.data);
        } else {
          // Handle custom plan (existing code)
          const customPlanData = {
            storage: this.selectedPlan.storage,
            duration: this.selectedPlan.duration,
            price: this.selectedPlan.price,
            group_members: this.selectedPlan.group_member,
          };
          const response = await axios.post("/custom-plan/", customPlanData, {
            headers: { requiresAuth: true },
          });
          this.handleRazorpayResponse(response.data);
        }
      } catch (error) {
        this.closeCheckoutDialog();
        this.error = "Failed to process purchase. Please try again.";
        console.error("Error processing purchase:", error);
      } finally {
        this.loading = false;
      }
    },

    async handleSubscription(data) {

      if (!this.razorpayScriptLoaded) {
        console.error("Razorpay not initialized");
        this.error =
          "Payment gateway is not available. Please try again later.";
        return;
      }

      const options = {
        key: data.razorpay_key,
        subscription_id: data.razorpay_subscription_id,
        name: "Yugin Tech pvt ltd",
        description: "Subscription Payment",
        handler: this.verifySubscription,
        prefill: {
          name: data.first_name + " " + data.last_name,
          email: data.email,
          contact: data.mobile_number,
        },
        theme: {
          color: "#3399cc",
        },
      };

      try {
        const rzp = new window.Razorpay(options);
        rzp.open();
      } catch (error) {
        console.error("Error opening Razorpay subscription dialog", error);
        this.error = "Failed to open payment gateway. Please try again.";
      }
    },

    async verifySubscription(response) {
      this.isVerifyingPayment = true;
      try {
        const verificationData = {
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_subscription_id: response.razorpay_subscription_id,
          razorpay_signature: response.razorpay_signature,
        };


        const verificationResponse = await axios.post(
          "/verify-subscription/",
          verificationData,
          {
            headers: { requiresAuth: true },
          }
        );


        if (verificationResponse.data.status === "success") {
          this.showPaymentSuccessDialog = true;
          this.startRedirectCountdown();
          this.closeCheckoutDialog();
          
        } else {
          throw new Error(verificationResponse.data.message);
        }
      } catch (error) {
        console.error("Error verifying subscription:", error);
        this.error = "Subscription verification failed. Please contact support.";
      }finally {
        this.isVerifyingPayment = false;
      }
    },

    selectPlan(plan) {
      this.selectedPlan = { ...plan }; // Create a copy of the plan
      this.isCustomPlan = false; // Regular plans are not custom
      this.showCheckoutDialog = true;
    },

    async handleRazorpayResponse(data) {

      if (!this.razorpayScriptLoaded) {
        console.error("Razorpay not initialized");
        this.error =
          "Payment gateway is not available. Please try again later.";
        return;
      }

      if (!data || !data.amount || !data.razorpay_order_id) {
        console.error("Invalid data received from server", data);
        this.error = "Invalid payment information received. Please try again.";
        return;
      }

      const options = {
        key: data.razorpay_key, // Use your Razorpay key here
        amount: data.amount,
        currency: "INR",
        name: "Yugin Tech pvt ltd",
        description: "One-Time Custom Plan Payment",
        order_id: data.razorpay_order_id,
        handler: this.handlePaymentSuccess,
        prefill: {
          name: data.first_name + " " + data.last_name,
          email: data.email || "",
          contact: data.mobile_number || "",
        },
        notes: {
          address: data.user_address || "",
        },
        theme: {
          color: "#3399cc",
        },
      };

      try {
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      } catch (error) {
        console.error("Error opening Razorpay payment dialog", error);
        this.error = "Failed to open payment gateway. Please try again.";
      }
    },

    async handlePaymentSuccess(response) {
      this.isVerifyingPayment = true;
      try {
        const verificationData = {
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          razorpay_signature: response.razorpay_signature,
        };


        const verificationResponse = await axios.post(
          "/verify-payment/",
          verificationData,
          {
            headers: { requiresAuth: true },
          }
        );

        if (verificationResponse.data.status === "success") {
          this.showPaymentSuccessDialog = true;
          this.startRedirectCountdown();
          this.closeCheckoutDialog();
          this.showCustomPlan = false;
        } else {
          throw new Error(verificationResponse.data.message);
        }
      } catch (error) {
        console.error("Error verifying payment:", error);
        this.error = "Payment verification failed. Please contact support.";
      }finally{
        this.isVerifyingPayment = false;
      }
    },

    startRedirectCountdown() {
      const countdownInterval = setInterval(() => {
        this.redirectCountdown--;
        if (this.redirectCountdown <= 0) {
          clearInterval(countdownInterval);
          this.$router.push('/my-hub');
        }
      }, 1000);
    },

    startCustomPlan() {
      this.isCustomPlan = true;
      this.showCustomPlan = true;
      this.calculateCustomPrice();
      // Don't set selectedPlan here, it will be set in confirmCustomPlan
    },

    closeCheckoutDialog() {
      this.showCheckoutDialog = false;
    },
    redirectToLogin() {
      // Implement the logic to redirect to the login page
      // For example: this.$router.push('/login');

      this.$router.push("/login");
    },
  },
  watch: {
    "userDetails.country"() {
      this.updateStates();
      this.userDetails.state = "";
    },
    showUserDetailsDialog(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.initializePhoneInput();
        });
      } else {
        if (this.iti) {
          this.iti.destroy();
          this.iti = null;
        }
      }
    },
  },
};
</script>

<style scoped>
.subscription-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 120px); /* Adjust based on header and footer height */
  color: var(--text-color);
  padding: 2rem 0;
}

.subscription-management {
  width: 100%;
  max-width: 1000px;
  padding: 2rem;
  border-radius: 0.5rem;
  background: var(--background-color);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: var(--text-color);
}

.plan-card {
  transition: transform 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.plan-card:hover {
  transform: translateY(-5px);
}
.plan-card .card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.price {
  font-size: 1.75rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
.period {
  font-size: 1rem;
  font-weight: normal;
}

.btn-primary {
  background-color: var(--background-color);
  color: var(--text-color);
  border-color: var(--text-color);
}

.btn-primary:hover {
  background-color: var(--text-color);
  color: var(--background-color);
}

.form-check-label a {
  color: var(--link-color);
}

.form-check-label a:hover {
  color: var(--hover-color);
}

.modal-content {
  background-color: var(--background-color);
  color: var(--text-color);
}

.modal-header {
  border-bottom-color: var(--border-color);
}

.modal-header .close {
  color: var(--text-color);
}

.modal {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: var(--background-color);
  color: var(--text-color);
}

.modal-header {
  border-bottom-color: var(--border-color);
}

.modal-header .close {
  color: var(--text-color);
}

.btn-success {
  background-color: var(--success-color);
  border-color: var(--success-color);
}

.modal {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: var(--background-color);
  color: var(--text-color);
}

.modal-header {
  border-bottom-color: var(--border-color);
}

.modal-header .close {
  color: var(--text-color);
}

.form-group {
  margin-bottom: 1rem;
}

.form-control {
  background-color: var(--input-background);
  color: var(--text-color);
  border-color: var(--border-color);
}

.btn-secondary {
  background-color: var(--secondary-button-color);
  border-color: var(--secondary-button-color);
  color: var(--text-color);
}

.btn-secondary:hover {
  background-color: var(--secondary-button-hover-color);
  border-color: var(--secondary-button-hover-color);
}

.iti {
  width: 100%;
}

.close {
  color: var(--text-color);
}

.accordion-item {
  margin-bottom: 1rem;
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
}

.accordion-header {
  background-color: var(--secondary-button-color);
  border-radius: 0.5rem;
}

.accordion-button {
  color: var(--text-color);
  background-color: var(--secondary-button-color);
  border: none;
  padding: 1rem;
  border-radius: 0.5rem;
}

.accordion-button:not(.collapsed) {
  background-color: var(--background-color);
  color: var(--text-color);
  box-shadow: none;
}

.accordion-body {
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 1rem;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-content {
  background-color: var(--background-color);
  padding: 2rem;
  border-radius: 0.5rem;
  text-align: center;
}

@media (max-width: 768px) {
  .subscription-management {
    padding: 1rem;
    width: 90%;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(14px);
}
</style>
