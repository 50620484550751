<template>
  <footer class="footer">
    <div class="container">
      <div class="footer-content" >
        <div id="copyright">
          &copy; Yugin Tech Pvt Ltd. 2024. All Rights Reserved.

        </div>
        <router-link to="/privacy">Privacy</router-link>
        <span>&middot;</span>
        <router-link to="/terms-and-conditions">Terms</router-link>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>

<style scoped>
footer.footer {
  background-color: var(--primary-color);
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding: 60px 0;
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;
}

.footer-content {
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

#copyright {
  font-size: 1rem;
}

.footer-content a {
  color: rgba(255, 255, 255, 0.6);
  transition: color 0.3s ease-in-out;
  font-weight: semibold;
  text-decoration: none;
  font-size: large;
}

.footer-content a:hover {
  color: rgba(255, 255, 255, 1);
}
</style>
