<template>
  <div class="app-container" 
       @dragenter.prevent="onDragEnter"
       @dragleave.prevent="onDragLeave"
       @dragover.prevent
       @drop.prevent="onDrop"
       :class="{ 'drag-active': isDragging }">
    <div class="main-content">
      <Sidebar :currentPage="currentPageName" />
      <div class="content-wrapper">
        <TopBar :pageTitle="pageTitle"
          :avatarUrl="aiPhotoUrl" 
          :userId="userId"/>
        <main class="content" 
        @dragenter.prevent="onDragEnter"
        @dragleave.prevent="onDragLeave"
        @dragover.prevent
        @drop.prevent="onDrop"
        :class="{ 'drag-active': isDragging }">
          <div class="container-fluid mt-4">
            <FloatingMessage :show="showMessage" :message="messageText" :type="messageType" />
<ProgressIndicator 
  :show="isUploading || isDownloading"
  :progress="isUploading ? uploadProgress : downloadProgress"
  :progressText="isUploading ? uploadProgressText : downloadProgressText"
/>
            <div class="row mb-4">
              <div class="col-12 col-sm-6">
              </div>
              <div class="col-12 col-sm-6 d-flex justify-content-sm-end mt-3 mt-sm-0">
              <button v-if="showGroupInfo" class="btn btn-outline-info me-2" @click="showGroupInfoModal">
              <i class="fas fa-info-circle me-2"></i>Group Info
            </button>
                <button class="btn btn-outline-secondary me-2" @click="uploadFiles">
                  <i class="fas fa-upload me-2"></i>Upload Files
                </button>
                <input type="file" ref="fileInput" @change="handleFileSelect" multiple style="display: none;">
                <button class="btn btn-primary" @click="createFolder">
                  <i class="fas fa-folder-plus me-2"></i>Create Folder
                </button>
              </div>
            </div>

            <div class="row mb-4 align-items-center">
              <div class="col-12 col-md-6 col-lg-4">
                <div class="input-group">
                  <span class="input-group-text bg-white border-end-0">
                    <i class="fas fa-search text-muted"></i>
                  </span>
                  <input type="text" class="form-control border-start-0" placeholder="Search files" v-model="searchQuery">
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 d-flex justify-content-md-end mt-3 mt-md-0">
                <button v-if="selectedItems.length > 0" class="btn btn-warning me-2" @click="unselectAll">
                  <i class="fas fa-times me-2"></i>Uncheck Selected
                </button>
                <button v-if="selectedItems.length > 0" class="btn btn-success me-2" @click="downloadSelectedItems">
                  <i class="fas fa-download me-2"></i>Download Selected
                </button>
                <button v-if="selectedItems.length > 0" class="btn btn-danger me-2" @click="deleteSelectedItems">
                  <i class="fas fa-trash me-2"></i>Delete Selected ({{ selectedItems.length }})
                </button>
                <button v-if="!isLoading" id="ai-face-filter" class="btn btn-outline-secondary me-2" @click="toggleAiFilter">
                  <i class="fas fa-user-circle"></i>
                  AI Face Filter
                </button>
                <button v-if="isLoading" id="ai-face-filter" class="btn btn-outline-secondary me-2" >
                  <i class="fas fa-spinner fa-spin"></i>
                </button>

                <div class="btn-group">
                  <button class="btn btn-outline-secondary" :class="{ active: viewMode === 'list' }" @click="viewMode = 'list'">
                    <i class="fas fa-list"></i>
                  </button>
                  <button class="btn btn-outline-secondary" :class="{ active: viewMode === 'grid' }" @click="viewMode = 'grid'">
                    <i class="fas fa-th"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col-12">
                <nav aria-label="breadcrumb" class="d-flex align-items-center">
                  <button v-if="navigationHistory.length > 1" @click="navigateBack" class="btn btn-outline-secondary me-2">
                    <i class="fas fa-arrow-left"></i> 
                  </button>
                  <ol v-if="navigationHistory.length > 1" class="breadcrumb mb-0">
                    <li v-for="(item, index) in navigationHistory" 
                        :key="index" 
                        class="breadcrumb-item"
                        :class="{ 'active': index === navigationHistory.length - 1 }">
                      <a v-if="index !== navigationHistory.length - 1" 
                         href="#" 
                         @click.prevent="navigateToHistoryItem(index)">
                        {{ item.name }}
                      </a>
                      <span v-else>{{ item.name }}</span>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>

            <div v-if="items.length === 0" class="text-center py-5">
              <i class="fas fa-cloud-upload-alt fa-5x mb-3 text-muted"></i>
              <h3 class="mb-3">No files or folders</h3>
              <p class="mb-4">Drag and drop files here or click the upload button to add files.</p>
              <button class="btn btn-primary" @click="uploadFiles">
                <i class="fas fa-upload me-2"></i>Upload Files
              </button>
            </div>

            <!-- Grid View -->
            <div v-if="viewMode === 'grid'" class="row g-4">
              <div v-for="item in itemsToDisplay" :key="item.uuid_id" class="col-6 col-sm-4 col-md-3 col-xl-2">
                <div class="card h-100 border-0 rounded-3 file-card position-relative" @click="handleItemClick(item)">
                  <div class="checkbox-overlay" :class="{ 'visible': item.selected || selectedItems.length > 0 }" @click.stop>
                    <input type="checkbox" :id="'checkbox-' + item.uuid_id" v-model="item.selected" class="form-check-input">
                  </div>
                  <div class="card-body text-center d-flex flex-column justify-content-center">
                    <div class="file-icon mb-3" :class="getItemColorClass(item)">
                      <i :class="getItemIcon(item)" class="fa-2x"></i>
                    </div>
                    <h6 class="card-title mb-0 text-truncate">{{ item.name }}</h6>
                    <p class="card-text small text-muted">{{ item.type }}</p>
                  </div>
                  <div class="dropdown position-absolute top-0 end-0 m-2" @click.stop>
                    <button class="btn btn-sm text-muted" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                      <i class="fas fa-ellipsis-v"></i>
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <li><a class="dropdown-item" href="#" @click.prevent="deleteItem(item)">Delete</a></li>
                      <li><a class="dropdown-item" href="#" @click.prevent="downloadItem(item)">Download</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <!-- List View -->
            <div v-else class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>
                      <input type="checkbox" class="form-check-input" :checked="selectAll" @change="toggleSelectAll" @click.stop>
                    </th>
                    <th>Name</th>
                    <th>File Size</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in filteredItems" :key="item.uuid_id" @click="handleItemClick(item)">
                    <td @click.stop>
                      <input type="checkbox" class="form-check-input" v-model="item.selected">
                    </td>
                    <td>
                      <div class="d-flex align-items-center">
                        <i :class="getItemIcon(item)" class="me-2"></i>
                        {{ item.name }}
                      </div>
                    </td>
                    <td>{{ item.fileSize }}</td>
                    <td @click.stop>
                      <div class="dropdown">
                        <button class="btn btn-sm text-muted" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <li><a class="dropdown-item" href="#" @click.prevent="deleteItem(item)">Delete</a></li>
                          <li><a class="dropdown-item" href="#" @click.prevent="downloadItem(item)">Download</a></li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </main>
      </div>
    </div>
     <!-- Drag and drop overlay -->
      <div v-if="isDragging" class="drag-overlay">
        <div class="drag-message">
          <i class="fas fa-cloud-upload-alt fa-3x mb-3"></i>
          <h3>Drop files here to upload</h3>
        </div>
      </div>

    <!-- Folder Creation Modal -->
    <div class="modal fade" id="createFolderModal" tabindex="-1" aria-labelledby="createFolderModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header bg-light">
            <h5 class="modal-title" id="createFolderModalLabel">
              <i class="fas fa-folder-plus me-2 text-primary"></i>Create New Folder
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="folderName" class="form-label">Folder Name</label>
              <input type="text" class="form-control" id="folderName" v-model="newFolderName" placeholder="Enter folder name">
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary" @click="confirmCreateFolder">
              <i class="fas fa-check me-2"></i>Create Folder
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Upload AI Photo Modal -->
    <div class="modal fade" id="uploadAiPhotoModal" tabindex="-1" aria-labelledby="uploadAiPhotoModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header bg-light">
            <h5 class="modal-title" id="uploadAiPhotoModalLabel">
              <i class="fas fa-camera me-2 text-primary"></i>Upload AI Photo
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="aiPhoto" class="form-label">Choose a photo</label>
              <input type="file" class="form-control" id="aiPhoto" ref="aiPhotoInput">
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary" @click="uploadAiPhoto">
              <i class="fas fa-upload me-2"></i>Upload Photo
            </button>
          </div>
        </div>
      </div>
    </div>
<!-- Enhanced File Viewer Modal -->
<div class="modal fade" id="fileViewerModal" tabindex="-1" aria-labelledby="fileViewerModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close" @click="closeModal">
          <i class="fas fa-arrow-left"></i>
        </button>
        <h5 class="modal-title" id="fileViewerModalLabel" v-if="currentFile">
          {{ currentFile.name }}
        </h5>
        <div class="action-buttons">
          <button class="btn btn-link" @click="zoomIn">
            <i class="fas fa-search-plus"></i>
          </button>
          <button class="btn btn-link" @click="zoomOut">
            <i class="fas fa-search-minus"></i>
          </button>
          <button class="btn btn-link" @click="downloadCurrentFile">
            <i class="fas fa-download"></i>
          </button>
          <button class="btn btn-link" @click="deleteCurrentFile">
            <i class="fas fa-trash"></i>
          </button>
        </div>
      </div>
      <div class="modal-body p-0" @touchstart="touchStart" @touchend="touchEnd">
        <div class="file-preview-container" :style="{ transform: `scale(${zoomLevel})` }">
          <div v-if="!currentFile">
            <p>No file selected.</p>
          </div>
          <div v-else-if="isImage(currentFile)" class="image-preview">
            <img :src="currentFile.file_url" :alt="currentFile.name">
          </div>
          <div v-else-if="isPDF(currentFile)" class="pdf-preview">
            <iframe :src="currentFile.file_url" width="100%" height="100%"></iframe>
          </div>
          <div v-else-if="isAudio(currentFile)" class="audio-preview">
            <audio controls class="w-100">
               <source :src="currentFile.file_url" :type="currentFile.type">
              Your browser does not support the audio element.
            </audio>
          </div>
          <div v-else-if="isVideo(currentFile)" class="video-preview">
            <video controls class="w-100 h-100">
              <source :src="currentFile.file_url" :type="currentFile.type">
              Your browser does not support the video tag.
            </video>
          </div>
          <div v-else-if="isTextFile(currentFile)" class="text-preview">
            <pre><code>{{ fileContent }}</code></pre>
          </div>
          <div v-else class="unsupported-preview">
            <p>Preview not available for this file type.</p>
            <button class="btn btn-primary" @click="downloadCurrentFile">
              <i class="fas fa-download me-2"></i>Download File
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  <!-- Group Info Modal -->
    <div class="modal fade" id="groupInfoModal" tabindex="-1" aria-labelledby="groupInfoModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-light">
          <h5 class="modal-title" id="groupInfoModalLabel">
            <i class="fas fa-users me-2 text-primary"></i>Group Information
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div v-if="groupInfo">
            <p><strong>Group Name:</strong> {{ groupInfo?.name }}</p>
            <p><strong>Description:</strong> {{ groupInfo.description }}</p>
            <p><strong>Created At:</strong> {{ formatDate(groupInfo.created_at) }}</p>
            
            <p><strong>Member Count:</strong> {{ groupInfo.member_count }}</p>
            <div v-if="groupInfo.members && groupInfo.members.length > 0">
              <h6 class="mt-4 mb-3">Members:</h6>
              <ul class="list-group">
                <li v-for="member in groupInfo.members" :key="member.uuid_id" class="list-group-item d-flex justify-content-between align-items-center">
                  <div>
                    <strong>{{ member.user.username }}</strong>
                    <span v-if="member.role === 'Admin'" class="badge bg-primary ms-2">Admin</span>
                    <br>
                    <small class="text-muted">{{ member.user.email }}</small>
                  </div>
                  <div>
                    <small>Joined: {{ formatDate(member.joined_at) }}</small>
                    <div v-if="isAdmin && member.user.uuid_id !== userId">
                      <button v-if="member.role !== 'Admin'" @click="showConfirmation('makeAdmin', member)" class="btn btn-sm btn-outline-primary me-1">
                        Make Admin
                      </button>
                      <button v-else @click="showConfirmation('removeAdmin', member)" class="btn btn-sm btn-outline-warning me-1">
                        Remove Admin
                      </button>
                      <button v-if="userId !== member.user.uuid_id" @click="showConfirmation('removeMember', member)" class="btn btn-sm btn-outline-danger">
                        Remove Member
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <p v-else>No member information available</p>

            <!-- Invite Members Section -->
            <div v-if="isAdmin" class="mt-4">
              <h6>Invite Members</h6>
              <div class="email-input-container">
                <div class="email-tags">
                  <span v-for="(email, index) in emailList" :key="index" class="email-tag">
                    {{ email }}
                    <button @click="removeEmail(index)" class="remove-email">&times;</button>
                  </span>
                </div>
                <input
                  type="text"
                  v-model="currentEmail"
                  @input="validateEmail"
                  @keydown="handleKeyDown"
                  placeholder="Enter email addresses"
                  class="form-control"
                />
              </div>
              <div v-if="emailError" class="text-danger mt-1">{{ emailError }}</div>
              <button @click="sendInvitations" class="btn btn-primary mt-2" :disabled="emailList.length === 0">
                Send Invitations
              </button>
            </div>
          </div>
          <div v-else>
            <p>No group information available.</p>
          </div>
        </div>
        <div class="modal-footer">
          <button v-if="isAdmin" @click="generateGroupInvitationToken" class="btn btn-primary me-2">
    <i class="fas fa-key me-2"></i>Generate Invitation Code
  </button>
          <button type="button" class="btn btn-danger" @click="showConfirmation('leaveGroup')">Leave Group</button>
          <button  type="button" class="btn btn-primary" data-bs-dismiss="modal">Close</button>

    </div>
        </div>
    </div>
    
  </div>

    <!-- Unified Confirmation Modal -->
    <div class="modal fade" id="confirmationModal" tabindex="-1" aria-labelledby="confirmationModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header" :class="confirmationModalHeaderClass">
            <h5 class="modal-title" id="confirmationModalLabel">{{ confirmationModalTitle }}</h5>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>{{ confirmationModalMessage }}</p>
            <p v-if="confirmationAction === 'leaveGroup' && isGroupCreator && groupInfo.member_count > 1" class="text-danger">
              <strong>Warning:</strong> As the group creator, you cannot leave until all other members have left the group.
            </p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="button" :class="confirmationModalButtonClass" @click="handleConfirmation" :disabled="isConfirmButtonDisabled">
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Invitation Token Modal -->
  <!-- Invitation Token Modal -->
<div class="modal fade" id="invitationTokenModal" tabindex="-1" aria-labelledby="invitationTokenModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header bg-primary text-white">
        <h5 class="modal-title" id="invitationTokenModalLabel">
          <i class="fas fa-key me-2"></i>Group Invitation Code
        </h5>
        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p class="mb-3">Use this token to invite others to join the group "<strong>{{ groupInfo?.name }}</strong>":</p>
        <div class="input-group mb-3">
          <input type="text" class="form-control" :value="invitationToken" readonly>
          <button class="btn btn-outline-secondary" type="button" @click="copyTokenToClipboard">
            <i class="fas fa-copy me-2"></i>Copy
          </button>
        </div>
        <p><strong>Expires:</strong> {{ invitationTokenExpiration }}</p>
        <div class="alert alert-info mt-3">
          <i class="fas fa-info-circle me-2"></i>
          To join the group, paste this code in the "Join Group" section.
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
    </div>
</template>
<script>
import Sidebar from './utils/SideBar.vue';
import TopBar from './utils/TopBar.vue';
import FloatingMessage from './utils/FloatingMessage.vue';
import ProgressIndicator from './utils/ProgressIndicator.vue';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import axios from '@/csrf/axios';
import { default as uploadAxios } from 'axios';
import { Modal } from 'bootstrap';
import heic2any from 'heic2any';

// import CodeViewer from './utils/CodeViewer.vue'; // Make sure you have this component


const fileTypeColors = {
  // Images
  'jpeg': 'deep-orange',
  'jpg': 'deep-orange',
  'png': 'deep-orange',
  'gif': 'deep-orange',
  'svg': 'deep-orange',
  'heif': 'deep-orange',
  'heic': 'deep-orange',

  // Videos
  'mp4': 'purple',
  'avi': 'purple',
  'mov': 'purple',
  'wmv': 'purple',
  'quicktime': 'purple',

  // Audio
  'mp3': 'amber',
  'wav': 'amber',
  'aac': 'amber',
  'flac': 'amber',

  // Documents
  'pdf': 'red',
  'doc': 'blue',
  'docx': 'blue',
  'xls': 'green',
  'xlsx': 'green',
  'ppt': 'orange',
  'pptx': 'orange',
  'txt': 'brown',
  'csv': 'blue-grey',

  // Archives
  'zip': 'pink',
  'rar': 'pink',
  '7z': 'pink',
  'iso': 'pink',

  // Code
  'html': 'orange-red',
  'css': 'orange-red',
  'js': 'orange-red',
  'py': 'orange-red',
  'java': 'orange-red',
  'c': 'orange-red',
  'cpp': 'orange-red',

  // Executables
  'exe': 'dark-magenta',
  'dmg': 'dark-magenta',

  // Folder
  'folder': 'goldenrod'
};


export default {
  name: 'FileManager',
  components: {
    Sidebar,
    TopBar,
    FloatingMessage,
    ProgressIndicator,

  },
  props: {
    files: {
      type: Array,
      default: () => [],
    },
    folders: {
      type: Array,
      default: () => [],
    },
    groupInfo: {
      type: Object,
      default: () => ({})
    },
    groupMembers: {
      type: Object,
      default: () => ({})
    },
    parentFolder: {
      type: String,
      default: '',
      required: true
    },
    userId: {
      type: String,
      default: '',
    },
    pageTitle: {
      type: String,
      default: '',
      required: true
    },
    currentPageName: {
      type: String,
      required: true
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    initialFolderId: {
      type: String,
      required: true
    },
    initialFolderName: {
      type: String,
      required: true
    },
    aiPhotoUrl: {
      type: String,
      default: ''
    },
    convertToJpeg: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      searchQuery: '',
      viewMode: 'grid',
      selectAll: false,
      parentFolderId: this.parentFolder,
      showMessage: false,
      messageText: '',
      messageType: 'error',
      messageTimeout: null,
      isDownloading: false,
      downloadProgress: 0,
      downloadProgressText: '',
      uploadProgress: 0,
      uploadProgressText: '',
      isUploading: false,
      newFolderName: '',
      folderModal: null,
      rootFolderId: this.parentFolder,
      folderPath: [], // Array to store the current folder path
      navigationHistory: [],
      currentFolderId: '',
      aiItems: [], // Array to store AI-detected items
      aiDetctionEnabled: false,
      currentFile: null,
      fileViewerModal: null,
      videoOptions: {
        autoplay: false,
        controls: true,
        sources: [],
      },
      touchStartX: 0,
      touchEndX: 0,
            zoomLevel: 1,
      fileContent: '',
            groupInfoModal: null,
      isGroupCreator: false,
       confirmationModal: null,
      confirmationAction: '',
      selectedMember: null,
      isCurrentUserAdmin: this.isAdmin,
      confirmationModalTitle: '',
      confirmationModalMessage: '',
      confirmationModalHeaderClass: '',
      confirmationModalButtonClass: '',
       currentEmail: '',
      emailList: [],
      emailError: '',
      isLoading: false,
      isDragging: false,
      invitationToken: '',
      invitationTokenExpiration: '',
      invitationTokenModal: null,

    };
  },
  computed: {
    items() {
      return [...this.folders, ...this.files];
    },
    selectedItems() {
      return this.items.filter(item => item.selected);
    },
    filteredItems() {
      return this.items.filter(item =>
        item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    isRootFolder() {
      return this.currentPath.length === 0;
    },
    itemsToDisplay() {
    return this.aiDetctionEnabled ? this.aiItems : this.filteredItems;
    },
  isConfirmButtonDisabled() {
      return this.confirmationAction === 'leaveGroup' && this.isGroupCreator && this.groupInfo.member_count > 1;
    },
    showGroupInfo() {
      // Determine whether to show group info based on the current route
      return this.$route.path.startsWith('/groups/') && this.groupInfo && Object.keys(this.groupInfo).length > 0;
    },
  },
  methods: {
    // Your component methods here

    toggleAiFilter() {
      this.checkAiPhotoUrl();
      if (this.aiDetctionEnabled) {
        this.aiDetctionEnabled = false;
      } else {
        this.aiItems = [];
      }
    },
    async handleItemClick(item) {
      if (item.name.includes('.')) {
        this.viewFile(item);
      } else {
        this.navigateToFolder(item.uuid_id);
      }
    },
    createFolder() {
      this.newFolderName = '';
      this.folderModal.show();
    },
    async confirmCreateFolder() {
      const folderName = this.newFolderName.trim();

      // Check if folder name is empty or exceeds 100 characters
      if (!folderName) {
        this.showFloatingMessage('Folder name cannot be empty', 'error');
        return;
      }
      if (folderName.length > 100) {
        this.showFloatingMessage('Folder name cannot exceed 100 characters', 'error');
        return;
      }

      // Check if folder name contains a file extension
      if (folderName.includes('.')) {
        this.showFloatingMessage('Folder name cannot contain a dot', 'error');
        return;
      }


      try {
        const response = await axios.post('/user-folder/create/', {
          folderName: this.newFolderName,
          folderId: this.parentFolder,
        }, {
          headers: { requiresAuth: true }
        });

        if (response.status === 201) {
          this.items.push(response.data);
          this.showFloatingMessage('Folder created successfully', 'success');
          this.folderModal.hide();
          this.$emit('dataUpdated'); // Emit event after successful upload
        } else {
          this.showFloatingMessage('Failed to create folder', 'error');
        }
      } catch (error) {
        console.error('Error creating folder:', error);
        this.showFloatingMessage('Failed to create folder', 'error');
      }
    },
    async navigateToFolder(folderId, folderName) {
      this.currentFolderId = folderId;
      let newUrl;

      if (this.navigationHistory.length === 0) {
        // For the root or initial folder, use the current route's full path
        newUrl = this.$route.fullPath;
      } else {
        // For nested folders, construct the URL
        newUrl = `/folder/${folderId}/`;
      }


      this.navigationHistory.push({ id: folderId, name: folderName, url: newUrl });

      if (this.navigationHistory.length > 1) {
        this.$router.push(newUrl);
      }

      await this.fetchFolderContents(folderId);
    },
    async navigateBack() {
      if (this.navigationHistory.length > 1) {
        this.navigationHistory.pop(); // Remove current folder
        const previousFolder = this.navigationHistory[this.navigationHistory.length - 1];
        this.currentFolderId = previousFolder.id;
        this.$router.push(previousFolder.url);
        await this.fetchFolderContents(previousFolder.id);
      }
    },
    async navigateToHistoryItem(index) {
      if (index < this.navigationHistory.length - 1) {
        this.navigationHistory = this.navigationHistory.slice(0, index + 1);
        const folder = this.navigationHistory[index];
        this.currentFolderId = folder.id;
        this.$router.push(folder.url);
        await this.fetchFolderContents(folder.id);
      }
    },
    async fetchFolderContents(folderId) {
      try {
        const response = await axios.get(`/user-folder/${folderId}/`, {
          headers: { requiresAuth: true }
        });
        this.parentFolderId = response.data.folder_id;
        this.items = response.data.contents;
        // Update rootFolderId if it's not set
        if (!this.rootFolderId) {
          this.rootFolderId = folderId;
        }
      } catch (error) {
        console.error('Error fetching folder contents:', error);
        this.showFloatingMessage('Failed to load folder contents', 'error');
      }
    },
    
    uploadFiles() {
      this.$refs.fileInput.click();
    },
    showFloatingMessage(message, type = 'error', duration = 3000) {
      this.messageText = message;
      this.messageType = type;
      this.showMessage = true;

      // Clear any existing timeout
      if (this.messageTimeout) {
        clearTimeout(this.messageTimeout);
      }

      // Set a new timeout to hide the message
      this.messageTimeout = setTimeout(() => {
        this.showMessage = false;
      }, duration);
    },

    async convertImage(file) {
       const fileType = file.type.toLowerCase();
      const fileName = file.name.toLowerCase();

      if (fileType === 'image/heif' || fileType === 'image/heic' || 
          fileName.endsWith('.heif') || fileName.endsWith('.heic')) {
        try {
          const convertedBlob = await heic2any({
            blob: file,
            toType: 'image/jpeg',
            quality: 1  // Highest quality
          });
          return new File([convertedBlob], file.name.replace(/\.(heif|heic)$/i, '.jpeg'), { type: 'image/jpeg' });
        } catch (error) {
          console.error('Error converting HEIF/HEIC:', error);
          throw new Error('Failed to convert HEIF/HEIC image');
        }
      } else if (fileType === 'image/gif' || fileName.endsWith('.gif')) {
        // For GIF, we'll maintain it as is to preserve animation
        return file;
      }
      return file;  
    },

     onDragEnter(e) {
        e.preventDefault();
    this.isDragging = true;
  },

  onDragLeave(e) {
    e.preventDefault();
    const rect = e.currentTarget.getBoundingClientRect();
    if (
      e.clientY < rect.top ||
      e.clientY >= rect.bottom ||
      e.clientX < rect.left ||
      e.clientX >= rect.right
    ) {
      this.isDragging = false;
    }
  },

  onDrop(e) {
    e.preventDefault();
    this.isDragging = false;
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      this.handleFileSelect({ target: { files } });
    }
  },

    async handleFileSelect(event) {
      let files = event.target.files;
      if (files.length === 0) return;

      this.isUploading = true;
      this.uploadProgress = 0;
      this.uploadProgressText = 'Preparing upload...';

      if(this.convertToJpeg) {
        const convertedFiles = [];
        for (const file of files) {
          const convertedFile = await this.convertImage(file);
          convertedFiles.push(convertedFile);
        }
        files = convertedFiles;
      } 
this.isUploading = true;
  this.uploadProgress = 0;
  this.uploadProgressText = 'Preparing upload...';

      try {
        // Step 1: Get pre-signed URLs
        const preSignResponse = await this.getPreSignedUrls(files);

        // Step 2: Upload files to S3
        await this.uploadFilesToS3(preSignResponse.pre_signed_urls, files);

        // Step 3: Confirm uploads
        await this.confirmUploads(preSignResponse.pre_signed_urls);

        this.showFloatingMessage('Files uploaded successfully', 'success');
      } catch (error) {
        console.error('Upload failed:', error);
        this.showFloatingMessage('File upload failed', 'error');
      } finally {
        this.isUploading = false;
        this.$refs.fileInput.value = ''; // Reset file input
      }
    },
    async getPreSignedUrls(files) {
      const filesInfo = Array.from(files).map(file => ({
        name: file.name,
        size: file.size,
        type: file.type
      }));

      const response = await axios.post('/upload/file/', {
        files: filesInfo,
        folderId: this.parentFolder // Ensure this is set correctly
      },
        {
          headers: { requiresAuth: true }
        });

      return response.data;
    },
    async uploadFilesToS3(preSignedUrls, files) {
      const totalFiles = preSignedUrls.length;
      let uploadedFiles = 0;

      for (let i = 0; i < totalFiles; i++) {
        const { pre_signed_url, original_filename } = preSignedUrls[i];
        const file = Array.from(files).find(f => f.name === original_filename);

        if (!file) {
          console.error(`File not found: ${original_filename}`);
          continue;
        }

        const formData = new FormData();
        Object.keys(pre_signed_url.fields).forEach(key => {
          formData.append(key, pre_signed_url.fields[key]);
        });
        formData.append('file', file);

        await uploadAxios.post(pre_signed_url.url, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            this.uploadProgress = ((uploadedFiles / totalFiles) + (percentCompleted / totalFiles / 100)) * 100;
            this.uploadProgressText = `Uploading file ${i + 1} of ${totalFiles}`;
          }
        });

        uploadedFiles++;

        preSignedUrls[i].size = file.size;
      }
    },
    async confirmUploads(preSignedUrls) {
      for (const fileInfo of preSignedUrls) {
        await axios.post('/confirm-upload/file/', {
          folderId: this.parentFolder,
          name: fileInfo.file_name,
          size: fileInfo.size,
          path: fileInfo.s3_path,
          mimeType: fileInfo.file_type
        },
          {
            headers: { requiresAuth: true }
          });
      }
      this.$emit('dataUpdated'); // Emit event after successful upload
    },
    async deleteSelectedItems() {
      if (this.selectedItems.length === 0) return;

      if (!confirm(`Are you sure you want to delete ${this.selectedItems.length} item(s)?`)) return;

      const fileIds = this.selectedItems.filter(item => item.name.includes('.')).map(item => item.uuid_id);
      const folderIds = this.selectedItems.filter(item => !item.name.includes('.')).map(item => item.uuid_id);
      const groupId = this.group && this.group.uuid_id ? this.group.uuid_id : null;

      try {
        const response = await axios.post(`/delete/folder-files/${this.parentFolder}/`, {
          file_ids: fileIds,
          folder_ids: folderIds,
          group_id: groupId // If applicable
        }, {
          headers: { requiresAuth: true }
        });

        if (response.status === 200) {
          // Remove deleted items from the list
          this.items = this.items.filter(item => !this.selectedItems.includes(item));
          this.selectAll = false;
          this.$emit('dataUpdated'); // Emit event after successful deletion

          this.showFloatingMessage('Selected items deleted successfully', 'success');
        }
      } catch (error) {
        console.error('Error deleting items:', error);
        this.showFloatingMessage('Failed to delete selected items', 'error');
      }
    },
    async deleteItem(item) {
      this.updateSelection(item);
      await this.deleteSelectedItems();
      this.selectedItems = [];
    },
    updateSelection(item) {
      item.selected = !item.selected;
      this.updateSelectAllStatus();
    },
    toggleSelectAll() {
      this.selectAll = !this.selectAll;
      this.items.forEach(item => item.selected = this.selectAll);
    },
    updateSelectAllStatus() {
      this.selectAll = this.items.length > 0 && this.items.every(item => item.selected);
    },
    unselectAll() {
      this.items.forEach(item => item.selected = false);
      this.selectAll = false;
    },
    async downloadItem(item) {
      this.isDownloading = true;
  this.downloadProgress = 0;
  this.downloadProgressText = `Downloading ${item.name}...`;
      const zip = new JSZip();
      try {
        const response = await uploadAxios.get(item.file_url, {

          onDownloadProgress: (progressEvent) => {
            this.downloadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          }
        });

        const now = new Date();
        const timestamp = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}_${String(now.getHours()).padStart(2, '0')}-${String(now.getMinutes()).padStart(2, '0')}-${String(now.getSeconds()).padStart(2, '0')}`;

        const zipFilename = `download_${timestamp}.zip`;
        zip.file(item.name, response.data);
        const content = await zip.generateAsync({ type: 'blob' });

        saveAs(content, zipFilename);
        this.showFloatingMessage(`${item.name} downloaded successfully`, 'success');
      } catch (error) {
        console.error('Error downloading item:', error);
        this.showFloatingMessage(`Failed to download ${item.name}`, 'error');
      }finally {
        this.isDownloading = false;
      }
    },
    async downloadSelectedItems() {
      if (this.selectedItems.length === 0) return;

      this.isDownloading = true;
      this.downloadProgress = 0;
      this.downloadProgressText = 'Preparing download...';

      const selectedFiles = this.selectedItems.filter(item => item.type !== 'folder');
      const zip = new JSZip();

      let completed = 0;
      for (const file of selectedFiles) {
        try {
          const response = await uploadAxios.get(file.file_url, {
            responseType: 'blob',
            onDownloadProgress: (progressEvent) => {
              const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              this.downloadProgress = ((completed / selectedFiles.length) + (progress / selectedFiles.length)) * 100;
            }
          });

          zip.file(file.name, response.data);
          completed++;
          this.downloadProgressText = `Downloaded ${completed} of ${selectedFiles.length} files...`;
        } catch (error) {
          console.error('Error downloading file:', error);
          this.showFloatingMessage(`Failed to download ${file.name}`, 'error');
        }
      }

      const now = new Date();
      const timestamp = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}_${String(now.getHours()).padStart(2, '0')}-${String(now.getMinutes()).padStart(2, '0')}-${String(now.getSeconds()).padStart(2, '0')}`;
      const zipFilename = `download_${timestamp}.zip`;
      try {
        const content = await zip.generateAsync({ type: 'blob' });

        saveAs(content, zipFilename);
        this.showFloatingMessage('Files downloaded successfully', 'success');
      } catch (error) {
        console.error('Error generating zip:', error);
        this.showFloatingMessage('Failed to generate zip file', 'error');
      }

      this.isDownloading = false;
    },
    getItemColorClass(item) {
  if (!item || !item.name) return 'text-secondary'; // Default color if item or item.name is undefined

  if (!item.name.includes('.')) return 'text-goldenrod'; // For folders

  const fileType = item.name.split('.').pop().toLowerCase();
  const color = fileTypeColors[fileType] || 'secondary';
  return `text-${color}`;
},

// Also update the getItemIcon method to handle the same case:
getItemIcon(item) {
  if (!item || !item.name) return 'fas fa-file'; // Default icon if item or item.name is undefined

  if (!item.name.includes('.')) {
    return 'fas fa-folder text-goldenrod';
  }
  const fileType = item.name.split('.').pop().toLowerCase();
  switch (fileType) {
    case 'zip': case 'rar': case '7z': return 'fas fa-file-archive';
    case 'mp4': case 'avi': case 'mov': case 'wmv': case 'quicktime': return 'fas fa-file-video';
    case 'xlsx': case 'xls': return 'fas fa-file-excel';
    case 'pdf': return 'fas fa-file-pdf';
    case 'jpeg': case 'jpg': case 'png': case 'gif': case 'svg': case 'heif': case 'heic': return 'fas fa-file-image';
    case 'docx': case 'doc': return 'fas fa-file-word';
    case 'mp3': case 'wav': case 'aac': case 'flac': return 'fas fa-file-audio';
    case 'ppt': case 'pptx': return 'fas fa-file-powerpoint';
    case 'txt': case 'csv': return 'fas fa-file-alt';
    case 'html': case 'css': case 'js': case 'py': case 'java': case 'c': case 'cpp': return 'fas fa-file-code';
    default: return 'fas fa-file';
  }
},
    async checkAiPhotoUrl() {
      if (!this.aiPhotoUrl) {
        this.showUploadAiPhotoModal();
      } else {
        await this.fetchAiDetectedItems();
      }
    },
    showUploadAiPhotoModal() {
      const modalElement = document.getElementById('uploadAiPhotoModal');
      const modalInstance = new Modal(modalElement);
      modalInstance.show();
    },
    async uploadAiPhoto() {
      const photoInput = this.$refs.aiPhotoInput;
      const photoFile = photoInput.files[0];

      if (!photoFile) {
        this.showFloatingMessage('Please select a photo to upload', 'error');
        return;
      }

      const formData = new FormData();
      formData.append('photo', photoFile);

      try {
        const response = await axios.post('/upload/user-ai-photo/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            requiresAuth: true
          }
        });

        if (response.status === 200) {
          this.showFloatingMessage('Photo uploaded successfully', 'success');
          const modalElement = document.getElementById('uploadAiPhotoModal');
          const modalInstance = Modal.getInstance(modalElement);
          modalInstance.hide();
          await this.fetchAiDetectedItems();
        } else {
          this.showFloatingMessage('Failed to upload photo', 'error');
        }
      } catch (error) {
        console.error('Error uploading photo:', error);
        this.showFloatingMessage('Failed to upload photo', 'error');
      }
    },
    async fetchAiDetectedItems() {
      this.isLoading = true;
      try {
            const response = await axios.get('/ai-detection/', {
      headers: { 
        requiresAuth: true 
      },
      params: {
        parent_folder_id: this.parentFolder
      }
    });

    if (response.status === 200) {
      if (response.data.error) {
        this.showFloatingMessage(response.data.error, 'info');
        this.aiDetctionEnabled = false;
        this.aiItems = [];
      } else {
        this.aiDetctionEnabled = true;
        this.aiItems = response.data;
        this.showFloatingMessage('AI-detected items fetched successfully', 'success');
      }
    } else {
      this.aiDetctionEnabled = false;
      this.showFloatingMessage('Failed to fetch AI-detected items', 'error');
    }
  } catch (error) {
    this.aiDetctionEnabled = false;
    console.error('Error fetching AI-detected items:', error);
    this.showFloatingMessage('Failed to fetch AI-detected items', 'error');
  }finally {
    this.isLoading = false;
  }
    },

    closeModal() {
      this.fileViewerModal.hide();
    },

handleKeyDown(event) {
      if (event.key === 'Escape') {
        this.closeModal();
      } else if (event.key === 'ArrowLeft') {
        this.showPreviousFile();
      } else if (event.key === 'ArrowRight') {
        this.showNextFile();
      } else if (event.key === '+') {
        this.zoomIn();
      } else if (event.key === '-') {
        this.zoomOut();
      } else if (event.key === 'Enter' || event.key === ',') {
        event.preventDefault();
        this.addEmail();
      } else if (event.key === 'Backspace' && this.currentEmail === '') {
        event.preventDefault();
        this.handleBackspace();
      }
      },

    zoomIn() {
      this.zoomLevel = Math.min(this.zoomLevel + 0.1, 3);
    },

    zoomOut() {
      this.zoomLevel = Math.max(this.zoomLevel - 0.1, 0.5);
    },

    resetZoom() {
      this.zoomLevel = .7;
    },

    touchStart(event) {
      this.touchStartX = event.changedTouches[0].screenX;
    },

    touchEnd(event) {
      this.touchEndX = event.changedTouches[0].screenX;
      this.handleSwipe();
    },

    handleSwipe() {
      const swipeThreshold = 50;
      if (this.touchStartX - this.touchEndX > swipeThreshold) {
        this.showNextFile();
      } else if (this.touchEndX - this.touchStartX > swipeThreshold) {
        this.showPreviousFile();
      }
    },

    showNextFile() {
      const currentIndex = this.items.findIndex(item => item.uuid_id === this.currentFile.uuid_id);
      if (currentIndex < this.items.length - 1) {
        this.viewFile(this.items[currentIndex + 1]);
      }
    },

    showPreviousFile() {
      const currentIndex = this.items.findIndex(item => item.uuid_id === this.currentFile.uuid_id);
      if (currentIndex > 0) {
        this.viewFile(this.items[currentIndex - 1]);
      }
    },
    async viewFile(file) {
      this.currentFile = file;
      this.resetZoom();
      if (this.isTextFile(file)) {
        await this.loadTextFileContent(file);
      }
      if (!this.fileViewerModal) {
        this.fileViewerModal = new Modal(document.getElementById('fileViewerModal'));
      }
      this.fileViewerModal.show();
      document.addEventListener('keydown', this.handleKeyDown);
    },

    async loadTextFileContent(file) {
      try {
        const response = await fetch(file.file_url);
        this.fileContent = await response.text();
      } catch (error) {
        console.error('Error loading text file:', error);
        this.fileContent = 'Error loading file content.';
      }
    },
  

  isImage(file) {
    if (!file || !file.name) return false;
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp', 'heic', 'heif'];
    return imageExtensions.includes(file.name.split('.').pop().toLowerCase());
  },

  isPDF(file) {
    return file && file.name && file.name.toLowerCase().endsWith('.pdf');
  },

  isAudio(file) {
    if (!file || !file.name) return false;
    const audioExtensions = ['mp3', 'wav', 'ogg', 'flac'];
    return audioExtensions.includes(file.name.split('.').pop().toLowerCase());
  },

  isVideo(file) {
    if (!file || !file.name) return false;
    const videoExtensions = ['mp4', 'webm', 'ogg', 'avi', 'mov', 'wmv', 'quicktime'];
    return videoExtensions.includes(file.name.split('.').pop().toLowerCase());
  },

  isTextFile(file) {
    if (!file || !file.name) return false;
    const textExtensions = ['txt', 'csv', 'json', 'xml', 'html', 'css', 'js', 'py', 'java', 'c', 'cpp'];
    return textExtensions.includes(file.name.split('.').pop().toLowerCase());
  },

  canUseWebView(file) {
    if (!file || !file.name) return false;
    const webViewExtensions = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'];
    return webViewExtensions.includes(file.name.split('.').pop().toLowerCase());
  },

  getWebViewUrl(file) {
    if (!file || !file.file_url) return '';
    return `https://docs.google.com/viewer?url=${encodeURIComponent(file.file_url)}&embedded=true`;
  },

  getFileLanguage(file) {
    if (!file || !file.name) return 'plaintext';
    const extension = file.name.split('.').pop().toLowerCase();
    const languageMap = {
      'js': 'javascript',
      'py': 'python',
      'java': 'java',
      'c': 'c',
      'cpp': 'cpp',
      'html': 'html',
      'css': 'css',
      'json': 'json',
      'xml': 'xml',
    };
    return languageMap[extension] || 'plaintext';
  },

  downloadCurrentFile() {
    if (this.currentFile) {
      this.downloadItem(this.currentFile);
    }
  },

  deleteCurrentFile() {
    if (this.currentFile) {
      this.deleteItem(this.currentFile);
      this.fileViewerModal.hide();
    }
    },
  showGroupInfoModal() {
      if (this.showGroupInfo) {
        if (!this.groupInfoModal) {
          this.groupInfoModal = new Modal(document.getElementById('groupInfoModal'));
        }
        this.groupInfoModal.show();
        this.checkIfGroupCreator();
      }
    },
    
    formatDate(dateString) {
      if (!dateString) return 'N/A';
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', timeZoneName: 'short' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },

    checkIfGroupCreator() {
      if (this.groupInfo?.members) {
        const currentUser = this.groupInfo.members.find(member => member.user.uuid_id === this.userId);
        this.isGroupCreator = currentUser && currentUser.role === 'Admin' && this.groupInfo.created_at === currentUser.joined_at;
      } else {
        this.isGroupCreator = false;
      }
    },

    showLeaveGroupConfirmation() {
      if (!this.leaveGroupModal) {
        this.leaveGroupModal = new Modal(document.getElementById('leaveGroupModal'));
      }
      this.leaveGroupModal.show();
    },
    
    async leaveGroup() {
      try {
        const response = await axios.post(`/groups/leave/${this.groupInfo.uuid_id}/`, {}, {
          headers: { requiresAuth: true }
        });
        
        if (response.status === 200) {
          this.showFloatingMessage(response.data.message, 'success');
          this.leaveGroupModal.hide();
          this.groupInfoModal.hide();
          // this.groupInfo = null;
          this.$emit('updateGroupList');
          this.$router.push('/my-hub');
        }
      } catch (error) {
        console.error('Error leaving group:', error);
        this.showFloatingMessage(error.response?.data?.error || 'Failed to leave the group', 'error');
      }
    },

    
    showConfirmation(action, member = null) {
      this.confirmationAction = action;
      this.selectedMember = member;

      switch (action) {
        case 'makeAdmin':
          this.confirmationModalTitle = 'Confirm Make Admin';
          this.confirmationModalMessage = `Are you sure you want to make ${member.user.username} an admin of this group?`;
          this.confirmationModalHeaderClass = 'bg-primary text-white';
          this.confirmationModalButtonClass = 'btn btn-primary';
          break;
        case 'removeAdmin':
          this.confirmationModalTitle = 'Confirm Remove Admin';
          this.confirmationModalMessage = `Are you sure you want to remove admin privileges from ${member.user.username}?`;
          this.confirmationModalHeaderClass = 'bg-warning text-white';
          this.confirmationModalButtonClass = 'btn btn-warning';
          break;
        case 'removeMember':
          this.confirmationModalTitle = 'Confirm Remove Member';
          this.confirmationModalMessage = `Are you sure you want to remove ${member.user.username} from the group?`;
          this.confirmationModalHeaderClass = 'bg-danger text-white';
          this.confirmationModalButtonClass = 'btn btn-danger';
          break;
        case 'leaveGroup':
          this.confirmationModalTitle = 'Confirm Leave Group';
          this.confirmationModalMessage = 'Are you sure you want to leave this group? This action cannot be undone.';
          this.confirmationModalHeaderClass = 'bg-danger text-white';
          this.confirmationModalButtonClass = 'btn btn-danger';
          break;
      }

      this.confirmationModal.show();
    },
    
    async handleConfirmation() {
      try {
        let response;
        switch (this.confirmationAction) {
          case 'makeAdmin':
            response = await axios.post(`/groups/make-admin/${this.groupInfo.uuid_id}/${this.selectedMember.user.uuid_id}/`, {}, {
              headers: { requiresAuth: true }
            });
            break;
          case 'removeAdmin':
            response = await axios.post(`/groups/remove-admin/${this.groupInfo.uuid_id}/${this.selectedMember.user.uuid_id}/`, {}, {
              headers: { requiresAuth: true }
            });
            break;
          case 'removeMember':
            response = await axios.delete(`/groups/remove-member/${this.groupInfo.uuid_id}/${this.selectedMember.user.uuid_id}/`, {
              headers: { requiresAuth: true }
            });
            break;
          case 'leaveGroup':
            this.leaveGroup();
            return;
        }
        
        if (response.status === 200) {
          this.showFloatingMessage(response.data.message, 'success');
          this.confirmationModal.hide();
          if (this.confirmationAction === 'leaveGroup') {
            this.$router.push('/my-hub');
          } else {
            this.$emit('dataUpdated');
          }
        }
      } catch (error) {
        console.error(`Error performing action: ${this.confirmationAction}`, error);
        this.showFloatingMessage(error.response?.data?.error || `Failed to perform action: ${this.confirmationAction}`, 'error');
      }
    },

  validateEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (this.currentEmail && !emailRegex.test(this.currentEmail)) {
        this.emailError = 'Invalid email format';
      } else {
        this.emailError = '';
      }
    },

    addEmail() {
      if (this.currentEmail && !this.emailError) {
        if (!this.emailList.includes(this.currentEmail)) {
          this.emailList.push(this.currentEmail);
        }
        this.currentEmail = '';
      }
    },

    removeEmail(index) {
      this.emailList.splice(index, 1);
    },

    handleBackspace() {
      if (this.currentEmail === '' && this.emailList.length > 0) {
        this.emailList.pop();
      }
    },

    async sendInvitations() {
      this.showFloatingMessage('Sending invitations...', 'info');
      try {
        const response = await axios.post('/invite/send/', {
          group_id: this.groupInfo.uuid_id,
          email: this.emailList
        }, {
          headers: { requiresAuth: true }
        });

        if (response.status === 200) {
          this.showFloatingMessage('Invitations sent successfully', 'success');
          this.emailList = [];
        }
      } catch (error) {
        console.error('Error sending invitations:', error);
        this.showFloatingMessage(error.response?.data?.error || 'Failed to send invitations', 'error');
      }
    },

    async generateGroupInvitationToken() {
      if (!this.groupInfo || !this.groupInfo.uuid_id) {
        this.showFloatingMessage('No group selected', 'error');
        return;
      }

      try {
        const response = await axios.post('/invite/generate-token/', {
          group_id: this.groupInfo.uuid_id
        }, {
          headers: { requiresAuth: true }
        });

        if (response.status === 200 || response.status === 201) {
          this.showInvitationTokenModal(response.data);
        }
      } catch (error) {
        console.error('Error generating invitation token:', error);
        this.showFloatingMessage(error.response?.data?.error || 'Failed to generate invitation token', 'error');
      }
    },

showInvitationTokenModal(data) {
      this.invitationToken = data.token;
      this.invitationTokenExpiration = new Date(data.expiration).toLocaleString();
      
      if (!this.invitationTokenModal) {
        this.invitationTokenModal = new Modal(document.getElementById('invitationTokenModal'));
      }
      this.invitationTokenModal.show();
    },

    copyTokenToClipboard() {
  navigator.clipboard.writeText('Paste or type this code in the "Join Group" section ' + this.invitationToken).then(() => {
    this.showFloatingMessage('Invitation token copied to clipboard', 'success');
  }, (err) => {
    console.error('Could not copy text: ', err);
    this.showFloatingMessage('Failed to copy invitation token', 'error');
  });
}
  
  },
  mounted() {
    this.folderModal = new Modal(document.getElementById('createFolderModal'));
    this.fileViewerModal = new Modal(document.getElementById('fileViewerModal'));
    this.groupInfoModal = new Modal(document.getElementById('groupInfoModal'));
    this.confirmationModal = new Modal(document.getElementById('confirmationModal'));
    this.invitationTokenModal = new Modal(document.getElementById('invitationTokenModal'));
      this.checkIfGroupCreator();


    const initialUrl = this.$route.fullPath;
    this.navigationHistory.push({
      id: this.initialFolderId,
      name: this.initialFolderName,
      url: initialUrl
    });
    this.currentFolderId = this.initialFolderId;
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  },
  updated() {
    if (!this.fileViewerModal) {
      const modalElement = document.getElementById('fileViewerModal');
      if (modalElement) {
        this.fileViewerModal = new Modal(modalElement);
      }
    }
  },
  watch: {
    items: {
      handler() {
        this.updateSelectAllStatus();
      },
      deep:true
    },
    $route(to, from) {
      // React to route changes
     const toGroupPage = to.path.startsWith('/groups/');
      const fromGroupPage = from.path.startsWith('/groups/');
      if (toGroupPage && !fromGroupPage) {
        // We've navigated to a group page
        this.$nextTick(() => {
          this.checkIfGroupCreator();
        });
      }
    },
   groupInfo: {
  handler(newGroupInfo) {
    if (newGroupInfo && Object.keys(newGroupInfo).length > 0 && this.$route.path.startsWith('/groups/')) {
      this.checkIfGroupCreator();
    }
  },
  deep: true
}
  }
};
</script>
<style scoped>
.app-container {
  display: flex;
  height: 100vh;
  font-family: var(--font-family);
  font-size: var(--font-size);
  color: var(--text-color);
  position: relative;
  overflow: hidden;
}

.main-content {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.content-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.content {
  flex-grow: 1;
  padding: 1rem;
  overflow-y: auto;
}

.file-card {
  transition: all 0.3s ease;
  background-color: var(--background-color);
  position: relative;
}

.file-card:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.file-icon {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: 12px;
}

.btn-outline-secondary {
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.btn-outline-secondary:hover,
.btn-outline-secondary.active {
  background-color: var(--primary-color);
  color: var(--accent-color);
}

.input-group-text,
.form-control {
  border-color: var(--border-color);
  background-color: var(--input-bg-color);
  color: var(--text-color);
}

.text-muted {
  color: var(--muted-color) !important;
}

.checkbox-overlay {
  position: absolute;
  top: 10px;
  left: 10px;
  opacity: 0;
  transition: opacity 0.3s ease;
  background-color: rgba(255, 255, 255, 0.8);
}

.file-card:hover .checkbox-overlay,
.checkbox-overlay.visible {
  opacity: 1;
}

.btn-danger {
  background-color: var(--error-color);
  border-color: var(--error-color);
  color: var(--accent-color);
}

.btn-danger:hover {
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:disabled {
  background-color: var(--muted-color);
  border-color: var(--muted-color);
}

.form-check-input {
  width: 1.2em;
  height: 1.2em;
}

.dropdown {
  position: relative;
}

.dropdown-menu {
  left: auto;
  right: 0;
  min-width: auto;
}

.table th,
.table td {
  vertical-align: middle;
}

.table .form-check-input {
  margin-top: 0;
}

/* Modal Styles */
.modal-content {
  border: none;
  border-radius: 8px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  background-color: var(--background-color);
  color: var(--text-color);
}

.modal-header {
  border-bottom: 1px solid var(--hover-color);
  background-color: var(--accent-color);
}

.modal-title {
  font-weight: 600;
  color: var(--text-color);
}

.modal-body {
  padding: 1.5rem;
}

.modal-footer {
  border-top: 1px solid var(--hover-color);
  padding: 1rem;
}

.form-label {
  font-weight: 500;
  color: var(--text-color);
}

.form-control {
  border-color: var(--hover-color);
  background-color: var(--background-color);
  color: var(--text-color);
}

.form-control:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
}

.btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--accent-color);
}

.btn-primary:hover {
  background-color: var(--link-color);
  border-color: var(--link-color);
}

.btn-secondary {
  background-color: var(--hover-color);
  border-color: var(--hover-color);
  color: var(--text-color);
}

.btn-secondary:hover {
  background-color: var(--link-color);
  border-color: var(--link-color);
  color: var(--accent-color);
}

.breadcrumb {
  background-color: transparent;
  padding: 0;
  margin-bottom: 0;
}

.btn-outline-secondary {
  padding: 0.375rem 0.75rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: var(--text-color);
}

.breadcrumb-item a {
  color: var(--link-color);
  text-decoration: none;
}

.breadcrumb-item a:hover {
  text-decoration: underline;
}

.breadcrumb-item.active {
  color: var(--text-color);
}

.text-muted {
  color: var(--muted-color) !important;
}

#ai-face-filter {
  border-color: var(--primary-color);
  color: var(--primary-color);
  transition: all 0.3s ease;
  padding: 0.375rem 0.75rem;
}

#ai-face-filter:hover {
  background-color: var(--hover-color);
}

#ai-face-filter.active {
  background-color: var(--primary-color);
  color: var(--accent-color);
}

#ai-face-filter:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

#ai-face-filter i {
  margin-right: 5px;
}

/* File Viewer Modal Styles */
#fileViewerModal .modal-content {
  border: none;
  height: 100vh;
}

#fileViewerModal .modal-header {
  background-color: rgb(25, 24, 24);
  border-bottom: 1px solid rgb(25, 24, 24);
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

#fileViewerModal .btn-close {
  background: none;
  color: whitesmoke;
  opacity: 1;
  font-size: 18px;
  padding: 0;
  margin-right: 15px;
}

#fileViewerModal .btn-close:hover {
  color: white;
}

#fileViewerModal .modal-title {
  flex-grow: 1;
  font-size: 18px;
  font-weight: normal;
  margin: 0;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#fileViewerModal .action-buttons {
  display: flex;
}

#fileViewerModal .action-buttons .btn-link {
  color: whitesmoke;
  font-size: 18px;
  text-decoration: none;
  padding: 8px 12px;
}

#fileViewerModal .action-buttons .btn-link:hover {
  background-color: #f1f3f4;
  border-radius: 4px;
}

#fileViewerModal .modal-body {
  padding: 0;
  height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  overflow: hidden;
}

#fileViewerModal .file-preview-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
}

#fileViewerModal .image-preview img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

#fileViewerModal .pdf-preview,
#fileViewerModal .video-preview {
  width: 100%;
  height: 100%;
}

#fileViewerModal .text-preview {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #fff;
  padding: 20px;
}

#fileViewerModal .text-preview pre {
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
}

#fileViewerModal .unsupported-preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

#fileViewerModal .unsupported-preview p {
  margin-bottom: 20px;
  font-size: 18px;
  color: #5f6368;
}

#fileViewerModal .unsupported-preview .btn-primary {
  background-color: #1a73e8;
  border-color: #1a73e8;
}

#fileViewerModal .unsupported-preview .btn-primary:hover {
  background-color: #174ea6;
  border-color: #174ea6;
}

.btn-outline-info {
  border-color: var(--info-color);
  color: var(--info-color);
}

.btn-outline-info:hover {
  background-color: var(--info-color);
  color: var(--accent-color);
}

#groupInfoModal .modal-dialog {
  max-width: 600px;
}

#groupInfoModal .modal-content {
  background-color: var(--background-color);
  color: var(--text-color);
}

#groupInfoModal .modal-header {
  border-bottom: 1px solid var(--border-color);
}

#groupInfoModal .modal-footer {
  border-top: 1px solid var(--border-color);
}

#groupInfoModal .btn-secondary {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  color: var(--accent-color);
}

#groupInfoModal .btn-secondary:hover {
  background-color: var(--secondary-hover-color);
  border-color: var(--secondary-hover-color);
}

#groupInfoModal .list-group-item {
  border-left: none;
  border-right: none;
}

#groupInfoModal .list-group-item:first-child {
  border-top: none;
}

#groupInfoModal .list-group-item:last-child {
  border-bottom: none;
}

.badge {
  font-size: 0.8em;
}

#groupInfoModal ul {
  list-style-type: none;
  padding-left: 20px;
}

#groupInfoModal li {
  margin-bottom: 5px;
}

#leaveGroupModal .modal-header {
  background-color: var(--error-color);
}

#leaveGroupModal .btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.modal-header.bg-primary,
.modal-header.bg-warning,
.modal-header.bg-danger {
  color: #fff;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.email-input-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 5px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.email-tags {
  display: flex;
  flex-wrap: wrap;
  margin-right: 5px;
}

.email-tag {
  background-color: #e9ecef;
  border-radius: 4px;
  padding: 2px 5px;
  margin: 2px;
  display: flex;
  align-items: center;
}

.remove-email {
  background: none;
  border: none;
  color: #6c757d;
  font-size: 14px;
  margin-left: 5px;
  cursor: pointer;
}

.email-input-container input {
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 5px;
}

.email-input-container input:focus {
  box-shadow: none;
}

.drag-active {
  border: 2px dashed var(--primary-color);
}

.drag-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.drag-message {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
}

.drag-message i {
  color: var(--primary-color);
}

.drag-message h3 {
  margin-top: 1rem;
  color: var(--text-color);
}

@media (max-width: 767px) {
  #fileViewerModal .action-buttons .btn-link {
    padding: 8px 6px;
  }
  
  #fileViewerModal .modal-title {
    font-size: 16px;
  }

  .content-wrapper {
    padding: 0.5rem;
  }

  .btn {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .btn-group {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .btn-group .btn {
    width: 50%;
  }

  .file-card {
    margin-bottom: 1rem;
  }

  .table-responsive {
    font-size: 0.9em;
  }

  .modal-dialog {
    margin: 0.5rem;
  }

  .email-input-container {
    flex-direction: column;
  }

  .email-tags {
    margin-bottom: 0.5rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .content {
    padding: 1rem;
  }

  .btn {
    padding: 0.375rem 0.5rem;
    font-size: 0.875rem;
  }

  #fileViewerModal .action-buttons .btn-link {
    padding: 8px 8px;
  }
}

@media (min-width: 992px) {
  .content {
    padding: 1.5rem;
  }
}
</style>