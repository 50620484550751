<template>
  <div class="content-manager">
    <div v-if="loading" class="loading-container">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <span>Loading...</span>
    </div>
    <div v-else-if="error" class="error-message">{{ error }}</div>
    <FileManager
      v-else
      :files="files"
      :folders="folders"
      :userId="userId"
      :parentFolder="parentFolderId"
      :currentPageName="pageType"
      :pageTitle="pageTitle"
      :groupInfo="groupInfo"
      :isAdmin="isAdmin"
      @dataUpdated="handleDataUpdate"
      :initialFolderId="parentFolderId"
      :initialFolderName="pageTitle"
      :aiPhotoUrl="userPhotoUrl"
      :convertToJpeg="convertImage"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import axios from '@/csrf/axios';
import FileManager from '../MainLayout.vue';
import router from '@/router';

/**
 * @typedef {'myHub' | 'group' | 'folder'} PageType
 */

export default defineComponent({
  name: 'ContentManager',
  components: {
    FileManager
  },
  data() {
    return {
      loading: true,
      /** @type {string | null} */
      error: null,
      /** @type {Array} */
      files: [],
      /** @type {Array} */
      folders: [],
      /** @type {string | null} */
      parentFolderId: null,
      /** @type {string | null} */
      userId: null,
      /** @type {string | null} */
      userPhotoUrl: null,
      isFetching: false,
      /** @type {Object | null} */
      groupInfo: null,
      isAdmin: false,
      pageTitle: '',
      convertImage: true
    };
  },
  computed: {
    /** @returns {PageType} */
    pageType() {
      const path = this.$route.path;
      if (path.startsWith('/groups/')) return 'group';
      if (path.startsWith('/folder/')) return 'folder';
      return 'myHub';
    },
    /** @returns {string} */
    apiEndpoint() {
      switch (this.pageType) {
        case 'myHub':
          return '/my-hub/';
        case 'group':
          return `/groups/${this.$route.params.id}/`;
        case 'folder':
          return `/user-folder/${this.$route.params.id}/`;
        default:
          return '';
      }
    },
    /** @returns {string | undefined} */
    id() {
      // @ts-ignore
      return this.$route.params.id;
    }
  },
  methods: {
    async fetchData() {
      if (localStorage.getItem('isLoggedIn') !== 'true') {
        this.loading = false;
        router.push('/login');
        return;
      }
      if (this.isFetching) {
        return;
      }
      this.isFetching = true;
      try {
        const response = await axios.get(this.apiEndpoint, {
          headers: { requiresAuth: true }
        });

        const response2 = await axios.get('/convert-image/info/', {
          headers: { requiresAuth: true }
        });
        
        this.files = response.data.files;
        this.folders = response.data.folders;
        this.userId = response.data.userId;
        this.convertImage = response2.data.convertToJpeg;
        
        if (this.pageType === 'myHub') {
          this.userPhotoUrl = response.data.aiPhotoUrl;
          this.parentFolderId = response.data.userFolderId;
          this.pageTitle = 'My Hub';
        } else if (this.pageType === 'group') {
          this.parentFolderId = response.data.groupFolderId;
          this.groupInfo = response.data.groupInfo.groupDetail;
          this.isAdmin = response.data.currentUserAdmin;
          this.pageTitle = this.groupInfo.name || 'Group';
          this.userPhotoUrl = response.data.userPhotoUrl;
          this.userId = response.data.groupInfo.user_id;
        } else if (this.pageType === 'folder') {
          this.parentFolderId = response.data.folder_id;
          this.pageTitle = response.data.folder_name || 'Folder Content';
          this.userPhotoUrl = response.data.userPhotoUrl;
        }
        this.loading = false;
      } catch (error) {
        console.error('Error fetching data:', error);
        this.error = `Failed to load ${this.pageType} data. Please try again.`;
        this.loading = false;
      } finally {
        this.isFetching = false;
      }
    },
    handleDataUpdate() {
      this.fetchData();
    }
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    $route() {
      this.fetchData();
    }
  }
});
</script>

<style scoped>
.content-manager {
  padding: 20px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  text-align: center;
  font-size: 1.5rem;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
  margin-bottom: 10px;
}

.error-message {
  color: red;
  text-align: center;
  font-size: 1.25rem;
  margin-top: 20px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .loading-container {
    font-size: 1.25rem;
  }
  .spinner-border {
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media (max-width: 480px) {
  .loading-container {
    font-size: 1rem;
  }
  .spinner-border {
    width: 2rem;
    height: 2rem;
  }
}
</style>
