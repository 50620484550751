<template>
  <div>
    <AppHeader></AppHeader>
    <!-- Yugin App Section -->
    <header class="masthead" data-aos="fade-down" data-aos-delay="50">
      <div class="container px-5">
        <div class="row gx-5 align-items-center">
          <div class="col-lg-6">
            <h1 class="display-1 lh-1 mb-3">Experience Freedom in File Management</h1>
            <p class="lead fw-normal text-muted mb-5">
              Securely store and effortlessly share your digital memories and documents with Yugin.
            </p>
            <div class="d-flex flex-column flex-lg-row align-items-center">
              <a class="app-badge-link" href="https://play.google.com/store/apps/details?id=com.yugintech.yugintech&hl=en_IN" data-aos="zoom-in" data-aos-delay="300">
                <img class="app-badge" src="@/assets/img/google-play-badge.svg" alt="Google Play Store" />
              </a>
              <a class="app-badge-link" href="https://apps.apple.com/in/app/yugin/id6503015584" data-aos="zoom-in" data-aos-delay="450">
                <img class="app-badge" src="@/assets/img/app-store-badge.svg" alt="App Store" />
              </a>
            </div>
          </div>
          <div class="col-lg-6" data-aos="fade-left" data-aos-delay="200">
            <img class="img-fluid align-to-text" src="@/assets/AdobeStock_498340208.jpeg" alt="Yugin App" />
          </div>
        </div>
      </div>
    </header>

    <!-- Aarya AI Section -->
    <section id="aarya-ai" class="py-5 bg-light" data-aos="fade-up" data-aos-delay="100">
      <div class="container px-5">
        <div class="row gx-5 align-items-center">
          <div class="col-lg-6 order-lg-2" data-aos="fade-left" data-aos-delay="200">
            <h2 class="display-4 mb-4">Meet Aarya AI</h2>
            <p class="lead mb-5" id="aarya-para">
              Aarya AI is your intelligent assistant powered by advanced GPT technology. Experience a new level of interaction and productivity.
            </p>
            <a href="http://aarya.yugin.co" class="btn btn-primary btn-lg">
              Explore Aarya AI
            </a>
          </div>
          <div class="col-lg-6 order-lg-1">
            <div class="row">
              <div class="col-md-6 mb-4" v-for="(feature, index) in aaryaFeatures" :key="index">
                <div class="feature-item">
                  <i :class="feature.icon"></i>
                  <h4>{{ feature.title }}</h4>
                  <p>{{ feature.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Key Features Section -->
    <section id="features" class="bg-light" data-aos="fade-up" data-aos-delay="100">
      <div class="container px-5 py-5">
        <h2 class="text-center mb-5">Key Features of Yugin Nebula</h2>
        <div class="row gx-5 justify-content-center">
          <div class="col-md-6 mb-5" data-aos="fade-right" data-aos-delay="200">
            <div class="text-center">
              <i class="fas fa-cloud-upload-alt icon-feature d-block mb-3"></i>
              <h3 class="font-alt">Cloud Backup</h3>
              <p class="text-muted mb-0">Your data is always safe with our secure, scalable cloud storage solutions.</p>
            </div>
          </div>
          <div class="col-md-6 mb-5" data-aos="fade-left" data-aos-delay="200">
            <div class="text-center">
              <i class="fas fa-users icon-feature d-block mb-3"></i>
              <h3 class="font-alt">Group Sharing</h3>
              <p class="text-muted mb-0">Share large files instantly within your created groups, with storage costs managed by the group creator.</p>
            </div>
          </div>
          <div class="col-md-6 mb-5" data-aos="fade-right" data-aos-delay="200">
            <div class="text-center">
              <i class="fas fa-user-shield icon-feature d-block mb-3"></i>
              <h3 class="font-alt">Privacy & Security</h3>
              <p class="text-muted mb-0">We prioritize your privacy with end-to-end encryption for all your files.</p>
            </div>
          </div>
          <div class="col-md-6 mb-5" data-aos="fade-left" data-aos-delay="200">
            <div class="text-center">
              <i class="fas fa-camera icon-feature d-block mb-3"></i>
              <h3 class="font-alt">AI Photo Management</h3>
              <p class="text-muted mb-0">Automatically organize and filter images using our advanced AI-driven face recognition technology.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Details Section -->
    <section class="bg-light" id="details" data-aos="fade-up" data-aos-delay="100">
      <div class="container px-5 py-5">
        <div class="row gx-5 align-items-center justify-content-center">
          <div class="col-12 col-lg-5">
            <h2 class="display-4 lh-1 mb-4">Revolutionize Your File Storage and Sharing</h2>
            <p class="lead fw-normal text-muted mb-5 mb-lg-0">
              Experience seamless file management with our intuitive interface, complemented by powerful AI features that enhance file organization and accessibility.
            </p>
          </div>
          <div class="col-sm-8 col-md-6">
            <div class="px-5 px-sm-0"><img class="img-fluid rounded-circle" src="@/assets/icon-final.png" alt="Explorers sharing content" /></div>
          </div>
        </div>
      </div>
    </section>

    <!-- FAQ Section -->
    <section id="faq" class="faq-section bg-light py-5" data-aos="fade-up" data-aos-delay="100">
      <div class="container">
        <h2 class="text-center mb-4">Frequently Asked Questions</h2>
        <div class="accordion" id="faqAccordion">
          <div class="accordion-item" v-for="(faq, index) in faqs" :key="index">
            <h2 class="accordion-header" :id="'heading' + index">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse' + index" aria-expanded="false" :aria-controls="'collapse' + index">
                {{ faq.question }}
              </button>
            </h2>
            <div :id="'collapse' + index" class="accordion-collapse collapse" :aria-labelledby="'heading' + index" data-bs-parent="#faqAccordion">
              <div class="accordion-body">
                {{ faq.answer }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Download Section -->
    <section class="bg-gradient-primary-to-secondary" id="download" style="padding: 60px 0;" data-aos="fade-up" data-aos-delay="100">
      <div class="container px-5">
        <h2 class="text-center text-white font-alt mb-4">Get the app now!</h2>
        <div class="d-flex flex-column flex-lg-row align-items-center justify-content-center">
          <a class="me-lg-3 mb-4 mb-lg-0" href="https://play.google.com/store/apps/details?id=com.yugintech.yugintech&hl=en_IN"><img class="app-badge" src="@/assets/img/google-play-badge.svg" alt="Google Play Store" /></a>
          <a href="https://apps.apple.com/in/app/yugin/id6503015584"><img class="app-badge" src="@/assets/img/app-store-badge.svg" alt="App Store" /></a>
        </div>
      </div>
    </section>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import AppHeader from '@/components/constant/AppHeader.vue'
import AppFooter from '@/components/constant/AppFooter.vue'
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  name: 'LandingPage',
  components: {
    AppHeader,
    AppFooter
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init({
        duration: 1200,
        mirror: true
      });
    });
  },
  data() {
    return {
      aaryaFeatures: [
        {
          icon: 'fas fa-comments fa-3x mb-3',
          title: 'Smart Conversations',
          description: 'Engage in intelligent dialogues and get assistance with various tasks.'
        },
        {
          icon: 'fas fa-lightbulb fa-3x mb-3',
          title: 'Personalized Recommendations',
          description: 'Receive tailored suggestions to enhance your productivity.'
        },
        {
          icon: 'fas fa-puzzle-piece fa-3x mb-3',
          title: 'Seamless Integration',
          description: 'Works flawlessly with all Yugin features for a smooth experience.'
        },
        {
          icon: 'fas fa-brain fa-3x mb-3',
          title: 'Continuous Learning',
          description: 'Constantly improves to provide better assistance over time.'
        }
      ],
      faqs: [
        {
          question: "How does cloud backup work?",
          answer: "Our cloud backup works by storing your data on secure servers online, which are accessible from any device, anywhere at any time."
        },
        {
          question: "Can I share files with non-users?",
          answer: " No, you cannot share files with anyone without an account. However, users can share files and folders within created groups, and these groups can be managed to include only the people you choose."
        },
        {
          question: "How do you ensure data privacy?",
          answer: "We use end-to-end encryption to ensure that your data is protected from unauthorized access at all times. Your data is encrypted before it leaves your device and remains encrypted until it reaches its intended recipient."
        },
        {
          question: "Can the company's employees access my data?",
          answer: " No, our employees cannot access your data. We ensure privacy by assigning unique identifiers to each user's data, which are inaccessible to anyone other than the user and those they have authorized."
        },
        {
          question: "What types of files can I store on your platform?",
          answer:"You can store any type of file on our platform, including documents, photos, videos, and music files. Our service is designed to handle a variety of file formats, providing flexibility for your storage needs."
        },
      ]
    };
  }
}
</script>

<style scoped>
.masthead {
  background: var(--background-color);
  padding-top: 80px;
  padding-bottom: 80px;
}

.app-badge-link {
  margin-right: 15px;
}

.app-badge {
  width: 150px;
  transition: transform 0.3s ease;
}

.app-badge:hover {
  transform: scale(1.05);
}

.display-1 {
  color: #333;
  font-weight: bold;
}

.lead {
  color: #555;
  font-size: 1.2rem;
}

.img-fluid {
  width: 100%;
  padding-top: 20px;
}

.align-to-text {
  width: 100%;
  height: auto;
}

/* Additional styles for the new layout */
#aarya-ai {
  background-color: var(--background-color);
}

#aarya-ai .display-4 {
  color: var(--primary-color);
  font-weight: bold;
}

#aarya-ai .lead {
  color: #555;
  font-size: 1.2rem;
}

#aarya-para {
  font-weight: 500;
}

.feature-item {
  padding: 20px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  height: 100%;
}

.feature-item:hover {
  transform: translateY(-5px);
}

.feature-item i {
  color: var(--primary-color);
  font-size: 2.5rem;
  margin-bottom: 15px;
}

.feature-item h4 {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.feature-item p {
  font-size: 0.9rem;
  color: #666;
}

.btn-primary {
  background-color: rgb(22 163 74);
  border-color: rgb(22 163 74);
  padding: 10px 25px;
  font-size: 1.1rem;
  transition: all 0.3s ease;
}

.btn-primary:hover {
  background-color: rgb(22 163 74); /* Example: slightly darker shade */
  border-color: rgb(22 163 74); /* Example: same darker shade */
  transform: translateY(-2px);
}


.icon-feature {
  font-size: 3rem;
  color: var(--primary-color);
}

.font-alt {
  font-weight: bold;
}

.bg-light {
  background-color: var(--background-color) !important;
}

.display-4 {
  font-weight: 300;
}

/* FAQ Section */
.faq-section {
  background-color: var(--background-color);
  padding: 40px 0;
}

.accordion-button {
  background-color: var(--background-color);
  color: var(--primary-color);
  font-weight: bold;
  font-size: 1.5rem;
  text-align: left;
  padding: 1.5rem 1.5rem;
}

.accordion-button:not(.collapsed) {
  color: var(--primary-color);
  background-color: var(--background-color);
  box-shadow: none;
}

.accordion-body {
  font-size: 1.2rem;
  padding: 1.5rem 1.5rem;
}

.accordion-header {
  margin-bottom: 0;
}

/* Download Section */
.bg-gradient-primary-to-secondary {
  background: linear-gradient(45deg, #2937f0, #9f1ae2) !important;
}

@media (max-width: 991px) {
  #aarya-ai .order-lg-1 {
    order: 2;
  }
  #aarya-ai .order-lg-2 {
    order: 1;
    margin-bottom: 2rem;
    text-align: center;
  }
}

/* Media Queries */
@media (max-width: 767px) {
  .icon-feature {
    font-size: 2.5rem;
  }

  .font-alt {
    font-size: 1.5rem;
  }

  .display-1 {
    font-size: 2rem;
  }

  .lead {
    font-size: 1.2rem;
  }

  .accordion-button {
    font-size: 1rem;
  }
  #aarya-ai .display-4 {
    font-size: 2rem;
  }
  #aarya-ai .lead {
    font-size: 1rem;
  }
  .feature-item {
    margin-bottom: 20px;
  }
}
</style>
