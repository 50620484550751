<template>
  <div>
    <AppHeader />
    <main class="signup-container">
      <div class="signup-form card shadow">
        <h1 class="text-center">Sign Up</h1>
        <!-- Note for Aarya AI users -->
        <p class="text-center mb-3">
          By signing up, you can use your credentials for both Yugin Nebula and Aarya AI.
        </p>
        <form @submit.prevent="register" novalidate>
          <div class="mb-3">
            <label for="username" class="form-label">Username</label>
            <input 
              type="text" 
              class="form-control" 
              id="username" 
              v-model.trim="credentials.username"
              :class="{ 'is-invalid': v$.credentials.username.$error }"
              @blur="v$.credentials.username.$touch()"
              required
            >
            <div class="invalid-feedback" v-if="v$.credentials.username.$error">
              {{ v$.credentials.username.$errors[0].$message }}
            </div>
          </div>
          <div class="mb-3">
            <label for="email" class="form-label">Email Address</label>
            <input 
              type="email" 
              class="form-control" 
              id="email" 
              v-model.trim="credentials.email"
              :class="{ 'is-invalid': v$.credentials.email.$error }"
              @blur="v$.credentials.email.$touch()"
              required
            >
            <div class="invalid-feedback" v-if="v$.credentials.email.$error">
              {{ v$.credentials.email.$errors[0].$message }}
            </div>
          </div>
          <div class="mb-3">
            <label for="password" class="form-label">Password</label>
            <div class="password-toggle">
              <input 
                :type="passwordFieldType" 
                class="form-control" 
                id="password" 
                v-model.trim="credentials.password"
                :class="{ 'is-invalid': v$.credentials.password.$error }"
                @blur="v$.credentials.password.$touch()"
                required
              >
              <button 
                type="button"
                class="toggle" 
                @click="togglePasswordVisibility"
                :aria-label="showPassword ? 'Hide password' : 'Show password'"
              >
                <i :class="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
              </button>
            </div>
            <div class="invalid-feedback" v-if="v$.credentials.password.$error">
              {{ v$.credentials.password.$errors[0].$message }}
            </div>
          </div>
          <div class="mb-3">
            <label for="confirmPassword" class="form-label">Confirm Password</label>
            <div class="password-toggle">
              <input 
                :type="confirmPasswordFieldType" 
                class="form-control" 
                id="confirmPassword" 
                v-model.trim="confirmPassword"
                :class="{ 'is-invalid': v$.confirmPassword.$error }"
                @blur="v$.confirmPassword.$touch()"
                required
              >
              <button 
                type="button"
                class="toggle" 
                @click="toggleConfirmPasswordVisibility"
                :aria-label="showConfirmPassword ? 'Hide confirmed password' : 'Show confirmed password'"
              >
                <i :class="showConfirmPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
              </button>
            </div>
            <div class="invalid-feedback" v-if="v$.confirmPassword.$error">
              {{ v$.confirmPassword.$errors[0].$message }}
            </div>
          </div>
          <div>
            <p class="text-center mt-3 tos">
              By signing up, you agree to our 
              <router-link to="/terms-and-conditions" class="tos-link">Terms of Service</router-link> 
              and 
              <router-link to="/privacy" class="tos-link">Privacy Policy</router-link>.
            </p>
          </div>
          <div v-if="errorMessage" class="alert alert-danger" role="alert">
            {{ errorMessage }}
          </div>
          <div class="d-grid">
            <button 
              type="submit" 
              class="btn" 
              id="signup-button"
              :disabled="isLoading"
            >
              <span v-if="!isLoading">Register</span>
              <span v-else class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </button>
          </div>
        </form>
        <p class="text-center mt-3">
          Already have an account? <router-link to="/login" class="signin-link">Sign In</router-link>
        </p>
      </div>
    </main>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email, minLength, sameAs } from '@vuelidate/validators'
import AppHeader from '@/components/constant/AppHeader.vue';
import axios from '@/csrf/axios';

export default {
  name: 'SignUpPage',
  components: {
    AppHeader
  },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      credentials: {
        username: '',
        email: '',
        password: '',
      },
      confirmPassword: '',
      showPassword: false,
      showConfirmPassword: false,
      errorMessage: '',
      isLoading: false,
    };
  },
  computed: {
    passwordFieldType() {
      return this.showPassword ? 'text' : 'password';
    },
    confirmPasswordFieldType() {
      return this.showConfirmPassword ? 'text' : 'password';
    }
  },
  validations() {
    return {
      credentials: {
        username: { required, minLength: minLength(5) },
        email: { required, email },
        password: { required, minLength: minLength(6) },
      },
      confirmPassword: { required, sameAsPassword: sameAs(this.credentials.password) }
    }
  },
  methods: {
    async register() {
      this.errorMessage = '';
      this.v$.$touch();
      if (this.v$.$invalid) return;

      this.isLoading = true;
      try {
        const response = await axios.post('/signup/', this.credentials, {
          headers: {
            requiresAuth: false,
          }
        });
        if (response.status === 201) {
          this.$router.push('/my-hub');
        }
      } catch (error) {
        console.error(error);
        this.errorMessage = error.response?.data?.error || 'Failed to register. Please try again.';
      } finally {
        this.isLoading = false;
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    toggleConfirmPasswordVisibility() {
      this.showConfirmPassword = !this.showConfirmPassword;
    }
  }
}
</script>

<style scoped>
.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  color: var(--text-color);
}

.signup-form {
  width: 100%;
  max-width: 400px;
  padding: 2rem;
  border-radius: 0.5rem;
  background: var(--background-color);
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  color: var(--text-color);
  transition: all 0.3s ease;
}

.password-toggle {
  position: relative;
}

.password-toggle .toggle {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: var(--primary-color);
}

.fas {
  color: var(--primary-color);
}

.alert {
  color: var(--error-color);
}

.signin-link, .tos-link {
  color: var(--link-color);
  transition: color 0.3s ease;
}

.signin-link:hover, .tos-link:hover {
  color: var(--hover-color);
}

.tos {
  color: var(--text-color);
  font-size: 0.8rem;
}

#signup-button {
  background-color: var(--background-color);
  color: var(--text-color);
  border-color: var(--text-color);
  transition: all 0.3s ease;
}

#signup-button:hover:not(:disabled) {
  background-color: var(--text-color);
  color: var(--background-color);
}

#signup-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.form-control {
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-control:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

@media (max-width: 576px) {
  .signup-form {
    padding: 1rem;
  }
}
</style>