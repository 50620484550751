<template>
    <div class="container">
        <div class="content">
            <h1 class="title">404 - Page Not Found</h1>
            <p class="description">The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
            <router-link to="/" class="home-link">Go to Home Page</router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotFoundPage'
}
</script>

<style scoped>
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    text-align: center;
    background-color: #f8f9fa;
    color: #333;
}

.content {
    padding: 20px;
}

.title {
    font-size: 2rem;
    font-weight: bold;
    color: #2c3e50;
}

.description {
    font-size: 1.2rem;
    margin: 20px 0;
    color: #34495e;
}

.home-link {
    padding: 10px 20px;
    background-color: var(--primary-color);
    color: #ffffff;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.home-link:hover {
    background-color: var(--primary-color);
}
</style>
