<template>
  <div>
    <AppHeader></AppHeader>
    <div class="terms-container">
      <h1>Terms and Conditions</h1>

      <h2>Welcome to Yugin Tech Pvt Ltd</h2>
      <p>These terms and conditions outline the rules and regulations for the use of Yugin Tech Pvt Ltd's Website and Mobile Application, located at https://yugin.co and on the Google Play Store and Apple App Store.</p>
      
      <p>For the purpose of these Terms and Conditions, the terms "we", "us", "our" used anywhere on this page shall mean Yugin Tech Pvt Ltd, whose registered/operational office is 41 third floor kuppu muthu mudali street Chennai TAMIL NADU 600005. "You", "your", "user", "visitor" shall mean any natural or legal person who is visiting our website and/or agreed to purchase from us.</p>

      <h2>Acceptance of Terms</h2>
      <p>By accessing this website and downloading our mobile application, we assume you accept these terms and conditions in full. Do not continue to use Yugin.co or the Yugin mobile app if you do not accept all of the terms and conditions stated on this page.</p>

      <h2>License to Use Website and Mobile App</h2>
      <p>Unless otherwise stated, Yugin Tech Pvt Ltd and/or its licensors own the intellectual property rights for all material on Yugin.co and the Yugin mobile app. All intellectual property rights are reserved. You may view and/or print pages from https://yugin.co for your own personal use subject to restrictions set in these terms and conditions.</p>
      
      <h2>Mobile Application License</h2>
      <p>When you download our mobile application, Yugin Tech Pvt Ltd grants you a revocable, non-exclusive, non-transferable, limited license to download, install, and use the app for your personal, non-commercial purposes strictly in accordance with the terms of this agreement.</p>

      <h2>Restrictions</h2>
      <p>You are specifically restricted from:</p>
      <ul>
        <li>Publishing any website or app material in any other media</li>
        <li>Selling, sublicensing and/or otherwise commercializing any website or app material</li>
        <li>Publicly performing and/or showing any website or app material</li>
        <li>Using this website or app in any way that is, or may be, damaging to this website or app</li>
        <li>Using this website or app in any way that impacts user access to this website or app</li>
        <li>Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions</li>
      </ul>

      <h2>User Content</h2>
      <p>"Your User Content" refers to any audio, video, text, images, or other material you choose to display on this website or app. By displaying Your User Content, you grant Yugin Tech Pvt Ltd a non-exclusive, worldwide, irrevocable license to use, reproduce for backup and functionality, and adapt your content solely for the purposes of operating, providing, and improving the services offered through yugin.co and its mobile application.</p>

      <h2>No Warranties</h2>
      <p>This website and app are provided "as is," with all faults, and Yugin Tech Pvt Ltd express no representations or warranties, of any kind related to this website or the materials contained on this website or app. Also, nothing contained on this website or app shall be interpreted as advising you.</p>

      <h2>Limitation of Liability</h2>
      <p>In no event shall Yugin Tech Pvt Ltd, nor any of its officers, directors, and employees, be held liable for anything arising out of or in any way connected with your use of this website or app whether such liability is under contract. Yugin Tech Pvt Ltd, including its officers, directors, and employees shall not be held liable for any indirect, consequential, or special liability arising out of or in any way related to your use of this website or app.</p>

      <h2>Indemnification</h2>
      <p>You hereby indemnify to the fullest extent Yugin Tech Pvt Ltd from and against any and/or all liabilities, costs, demands, causes of action, damages, and expenses arising in any way related to your breach of any of the provisions of these terms.</p>

      <h2>Severability</h2>
      <p>If any provision of these terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.</p>

      <h2>Variation of Terms</h2>
      <p>Yugin Tech Pvt Ltd is permitted to revise these terms at any time as it sees fit, and by using this website or app you are expected to review these terms on a regular basis.</p>

      <h2>Assignment</h2>
      <p>Yugin Tech Pvt Ltd is allowed to assign, transfer, and subcontract its rights and/or obligations under these terms without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these terms.</p>

      <h2>Governing Law & Jurisdiction</h2>
      <p>These terms will be governed by and interpreted in accordance with the laws of the Country of India, and you submit to the non-exclusive jurisdiction of the state and federal courts located in India for the resolution of any disputes.</p>

      <h2>Additional Provisions</h2>
      <ul>
        <li>The content of the pages of this website is subject to change without notice.</li>
        <li>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose.</li>
        <li>You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</li>
        <li>Your use of any information or materials on our website and/or product pages is entirely at your own risk, for which we shall not be liable.</li>
        <li>It shall be your own responsibility to ensure that any products, services or information available through our website and/or product pages meet your specific requirements.</li>
        <li>All trademarks reproduced in our website which are not the property of, or licensed to, the operator are acknowledged on the website.</li>
        <li>Unauthorized use of information provided by us shall give rise to a claim for damages and/or be a criminal offense.</li>
        <li>From time to time our website may also include links to other websites. These links are provided for your convenience to provide further information.</li>
        <li>You may not create a link to our website from another website or document without Yugin Tech Pvt Ltd's prior written consent.</li>
        <li>We shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on Account of the Cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time.</li>
      </ul>

      <!-- Aarya AI Section -->
      <h2>Aarya AI</h2>
      <p>Aarya AI is an artificial intelligence feature provided by Yugin Tech Pvt Ltd. By using Aarya AI, you agree to the following terms:</p>
      <ul>
        <li>Aarya AI is designed to assist users and enhance their experience within the Yugin platform.</li>
        <li>The responses generated by Aarya AI are based on machine learning algorithms and may not always be accurate or appropriate for all situations.</li>
        <li>Users should exercise discretion and judgment when relying on information or suggestions provided by Aarya AI.</li>
        <li>Yugin Tech Pvt Ltd is not responsible for any actions taken or decisions made based on Aarya AI's outputs.</li>
        <li>The use of Aarya AI is subject to our privacy policy, and users should be aware that interactions with Aarya AI may be logged and analyzed for improving the service.</li>
        <li>Yugin Tech Pvt Ltd reserves the right to modify, suspend, or discontinue Aarya AI at any time without prior notice.</li>
      </ul>
  
      <h2>Cancellation and Refund Policy</h2>
      <p>No cancellation and refunds are entertained for any reason whatsoever.</p>

      <h2>Contact Us</h2>
      <h5>Operational address</h5>
      <p> Yugin Tech Pvt Ltd, 3rd Floor, 41 kuppu muthu mudali street, Chennai - 600 005</p>
      <p>If you have any questions about these Terms and Conditions, please contact us at support@yugin.co.</p>
    </div>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import AppHeader from '@/components/constant/AppHeader.vue';
import AppFooter from '@/components/constant/AppFooter.vue';

export default {
  name: 'TermsAndConditionsPage',
  components: {
    AppHeader,
    AppFooter
  }
}
</script>

<style scoped>
.terms-container {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  padding-top: 50px;
}

h1, h2 {
  color: var(--text-color);
}

p, ul {
  color: var(--text-color);
  line-height: 1.6;
}

ul {
  padding-left: 20px;
}
</style>
