const folderModule = {
  namespaced: true,
  state: {
    folderPath: [],
  },
  mutations: {
    ADD_FOLDER_TO_PATH(state, folder) {
      state.folderPath.push(folder);
    },
    REMOVE_LAST_FOLDER(state) {
      state.folderPath.pop();
    },
    CLEAR_PATH_FROM_INDEX(state, index) {
      state.folderPath = state.folderPath.slice(0, index + 1);
    },
    SET_FOLDER_PATH(state, path) {
      state.folderPath = path;
    },
  },
  actions: {
    addFolderToPath({ commit }, folder) {
      commit('ADD_FOLDER_TO_PATH', folder);
    },
    removeLastFolder({ commit }) {
      commit('REMOVE_LAST_FOLDER');
    },
    clearPathFromIndex({ commit }, index) {
      commit('CLEAR_PATH_FROM_INDEX', index);
    },
    setFolderPath({ commit }, path) {
      commit('SET_FOLDER_PATH', path);
    },
  },
  getters: {
    currentPath: state => state.folderPath,
  },
};

export default folderModule;