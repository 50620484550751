<template>
  <transition name="fade">
    <div v-if="show" class="floating-message" :class="type">
      {{ message }}
    </div>
  </transition>
</template>

<script>
export default {
  name: 'FloatingMessage',
  props: {
    show: Boolean,
    message: String,
    type: {
      type: String,
      default: 'error',
      validator: (value) => ['error', 'success', 'info', 'warning'].includes(value)
    }
  }
}
</script>

<style scoped>
.floating-message {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 15px 20px;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  z-index: 9999;
}

.error {
  background-color: #dc3545;
}

.success {
  background-color: #28a745;
}

.info {
  background-color: #17a2b8;
}

.warning {
  background-color: #ffc107;
  color: #343a40;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>