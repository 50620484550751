<template>
  <AppHeader></AppHeader>
  <div class="privacy-container">
    <h1>Privacy Policy</h1>

    <h2>Welcome</h2>
    <p>Welcome to Yugin Tech Pvt Ltd, where we are committed to protecting your privacy and handling your data responsibly. This document outlines how we collect, use, and safeguard the information you provide while using our services.</p>

    <h2>Information Collection and Use</h2>
    <p>We collect various types of information for various purposes to provide and improve our service to you.</p>
    <ul>
      <li><strong>Personal Identifiers:</strong> We collect information such as email addresses, phone numbers, and personal photographs.</li>
      <li><strong>Usage Data:</strong> This includes details of how you use our service, such as your search queries and interactions with our features.</li>
    </ul>

    <h2>AI Face Detection</h2>
    <p>We use AI face detection technology to enhance certain features of our service. This technology processes visual data to detect and analyze facial features, allowing us to filter and organize photos based on the detected faces. Users can manage their photos more efficiently, and they have the option to delete their face data whenever they choose.</p>

    <h2>Face Data Retention</h2>
    <p>We do not retain face data indefinitely. Face data is used solely for the purpose of enhancing user experience through face recognition technology and is not stored beyond the immediate use required for this purpose.</p>

    <h2>Facial Data</h2>
    <p>We store your facial data (upload your face or take a selfie) to find your photos among all photos inside a group using face recognition. The face data will be saved until the user deletes or uploads a new face photo or selfie, or until 10 years have passed from the date of upload. This specific duration is necessary to provide continuous and improved service without requiring frequent re-uploads, ensuring convenience for the user.</p>
    <p>We do not share your facial data with third parties.</p>

     <h2>Aarya AI Privacy</h2>
    <p>Aarya AI is a powerful assistant similar to GPT, designed to help users with a variety of tasks through natural language processing. We take your privacy seriously when it comes to using Aarya AI, and this section outlines how your data is handled in relation to this feature.</p>
    <ul>
      <li><strong>Data Collection:</strong> Aarya AI processes the text inputs and commands you provide, including any personal data or sensitive information you may include in your interactions. This data is used to generate responses and provide the services you request.</li>
      <li><strong>Data Storage:</strong> Interaction data with Aarya AI may be temporarily stored to improve the AI's responses and for quality assurance. However, this data is anonymized and is not linked to your personal identifiers unless necessary for the functionality you requested.</li>
      <li><strong>Security:</strong> We use encryption and other security measures to protect the data processed by Aarya AI. Data is transmitted securely and stored in a way that prevents unauthorized access.</li>
      <li><strong>Data Sharing:</strong> We do not share the data processed by Aarya AI with third parties for marketing or non-essential purposes. Any sharing of data is limited to service providers who assist in improving and operating the AI, under strict confidentiality agreements.</li>
      <li><strong>Use for Model Improvement:</strong> Interaction data may be used to improve Aarya AI’s underlying models. This process is done in a way that anonymizes the data to protect your identity and personal information.</li>
    </ul>

    <h2>Children's Privacy</h2>
    <p>Our services are designed for a wide audience, but we take extra precautions to protect the privacy of children under the age of 13. Parents and guardians are encouraged to take an active role in their children’s online activities and interests.</p>

    <h2>Use of Data</h2>
    <p>Your information helps us provide and improve our services. Here’s how we use your data:</p>
    <ul>
      <li>To provide and maintain our service</li>
      <li>To notify you about changes to our service</li>
      <li>To allow you to participate in interactive features of our service when you choose to do so</li>
      <li>To provide customer care and support</li>
      <li>To provide analysis or valuable information so that we can improve the service</li>
      <li>To monitor the usage of the service</li>
      <li>To detect, prevent, and address technical issues</li>
      <li>For research and training purposes to improve our AI models</li>
    </ul>

    <h2>Data Privacy & Security</h2>
    <p>We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information.</p>

    <h2>Third-Party Access</h2>
    <p>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website and mobile app, conducting our business, or servicing you, so long as those parties agree to keep this information confidential.</p>

    <h2>Your Data Protection Rights</h2>
    <p>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
    <ul>
      <li>The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</li>
      <li>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</li>
    </ul>

    <h2>Retention of Your Personal Data</h2>
    <p>The Company will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>

    <h2>Transfer of Your Personal Data</h2>
    <p>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>

    <h2>Disclosure of Your Personal Data</h2>
    <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).</p>

    <h2>Security of Your Personal Data</h2>
    <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>

    <h2>Changes to This Privacy Policy</h2>
    <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. We will let you know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.</p>

    <h2>Contact Us</h2>
    <p>If you have any questions about this Privacy Policy, please contact us at support@yugin.co.</p>
  </div>
  <AppFooter></AppFooter>
</template>

<script>
import AppHeader from '@/components/constant/AppHeader.vue'
import AppFooter from '@/components/constant/AppFooter.vue'

export default {
  name: 'PrivacyPolicyPage',
  components: {
    AppHeader,
    AppFooter
  }
}
</script>

<style scoped>
.privacy-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  padding-top: 100px;
  font-family: Arial, sans-serif;
}

h1 {
  text-align: center;
  color: var(--primary-color);
  font-size: 3rem;
}

h2 {
  color: var(--primary-color);
  margin-top: 20px;
  font-size: 2rem;
}

p, ul {
  line-height: 1.6;
  color: var(--text-color);
  font-size: 1.1rem;
}

ul {
  padding-left: 20px;
}
</style>
