<template>
  <header class="top-bar">
    <h1 class="page-title">{{ pageTitle }}</h1>
    <div class="right-section">
      <div class="user-menu">
        <div class="avatar" @click="toggleDropdown">
          <img :src="computedAvatarUrl" alt="Profile" class="profile-picture">
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'TopBar',
  props: {
    pageTitle: {
      type: String,
      required: true
    },
    avatarUrl: {
      type: String,
      default: ''
    },
    userId: {
      type: String,
      required: true
    }
  },
  computed: {
    computedAvatarUrl() {
      if (this.avatarUrl) {
        return this.avatarUrl;
      } else {
        const uniqueIdentifier = this.userId || Math.random().toString(36).substring(7);
        return `https://robohash.org/${uniqueIdentifier}?size=40x40&set=set4`;
      }
    }
  },
  methods: {
  }
}
</script>

<style scoped>
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--spacing-medium);
  height: 60px;
  background-color: var(--background-color);
  border-bottom: 1px solid var(--hover-color);
}

.page-title {
  font-size: 24px;
  font-weight: bold;
}

.right-section {
  display: flex;
  align-items: center;
}

.theme-toggle, .user-menu {
  margin-left: var(--spacing-medium);
}

.user-menu {
  position: relative;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.default-avatar {
  width: 100%;
  height: 100%;
  background-color: #007bff;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
}
</style>
