<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App',

}
</script>

<style>
:root {
  /* Colors */
  --primary-color: #000000; /* Black */
  --accent-color: #FFFFFF; /* White */
  --background-color: #FFFFFF; /* White */
  --text-color: #000000; /* Black */
  --link-color: #333333; /* Dark Gray */
  --hover-color: #CCCCCC; /* Light Gray */
  --error-color: #c82333; /* Red */
  --success-color: #077307; /* Green */
   --bg-secondary: #F5F5F5; /* Light gray for secondary backgrounds */
  --bg-tertiary: #E0E0E0; /* 
  

  /* Fonts */
  --font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --font-size: 16px;

  /* Spacing */
  --spacing-small: 8px;
  --spacing-medium: 16px;
  --spacing-large: 24px;

  /* Border Radius */
  --border-radius-small: 4px;
  --border-radius-medium: 8px;
  --border-radius-large: 12px;

  /* Shadows */
  --box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  
}

</style>
