<template>
    <AppHeader></AppHeader>
    <div class="resetpassword-container" :style="backgroundStyle">
      <div class="resestpassword-form card shadow">
        <h2 class="text-center">Forgot Password</h2>
        <p class="text-center">Enter your email address and we'll send you a link to reset your password.</p>
        <form @submit.prevent="submitForgotPassword">
          <div class="mb-3">
            <label for="email" class="form-label">Email Address</label>
            <input type="email" class="form-control" id="email" v-model="email" required>
          </div>
          <div v-if="errorMessage" class="alert alert-danger">
            {{ errorMessage }}
          </div>
          <div v-if="successMessage" class="alert alert-success">
  {{ successMessage }}
</div>

          <div class="d-grid">
            <button type="submit" class="btn btn-primary" id="reset-button">Send Reset Link</button>
          </div>
        </form>
        <p class="text-center mt-3">
          Remembered your password? <router-link to="/login" class="signin-link">Sign In</router-link>
        </p>
      </div>
    </div>
  </template>
  
  <script>
import AppHeader from '@/components/constant/AppHeader.vue'
import axios from '@/csrf/axios'; // Ensure axios is imported to make HTTP requests

export default {
  name: 'ForgotPasswordPage',
  components: {
    AppHeader
  },
  data() {
    return {
      email: '',
      errorMessage: '',
      successMessage: '',
      backgroundImages: [
        'https://example.com/path-to-your-image1.jpg',
        'https://example.com/path-to-your-image2.jpg',
        'https://example.com/path-to-your-image3.jpg'
      ]
    };
  },
  computed: {
    backgroundStyle() {
      const randomImage = this.backgroundImages[Math.floor(Math.random() * this.backgroundImages.length)];
      return {
        backgroundImage: `url("${randomImage}")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      };
    }
  },
  methods: {
    async submitForgotPassword() {
      this.errorMessage = ''; // Clear previous errors
      this.successMessage = ''; // Clear previous messages
      if (!this.email) {
        this.errorMessage = 'Email address is required.';
        return;
      }
      try {
        const response = await axios.post('/forgot-password/', { email: this.email },
          {
            headers: {
              requiresAuth: false,
            }
          }
        );
        if (response.data && response.data.message) {
          this.successMessage = response.data.message; // Handle success
        } else {
          this.successMessage = 'A link to reset your password has been sent if the email is registered.';
        }
      } catch (error) {
        if (error.response && error.response.data) {
          this.errorMessage = error.response.data.error || 'Failed to send reset link.';
        } else {
          this.errorMessage = 'Network error or server not responding.';
        }
      }
    }
  }
}
</script>

  
  <style scoped>
  .resetpassword-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    color: var(--text-color);
  }
  
  .resestpassword-form {
    width: 100%;
    max-width: 400px;
    padding: 2rem;
    border-radius: 0.5rem;
    background: var(--background-color);
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    color: var(--text-color);
  }
  
  .alert {
    color: var(--error-color);
  }

  .alert-success {
    color: var(--primary-color);
  }
  
  #reset-button {
    background-color: var(--background-color);
    color: var(--text-color);
    border-color: var(--text-color);
  }

  #reset-button:hover {
    background-color: var(--text-color);
    color: var(--background-color);
  }

  .signin-link {
    color: var(--link-color);
  }
  
  .signin-link:hover {
    color: var(--hover-color);
  }
  
  @media (max-width: 576px) {
    .resestpassword-form {
      padding: 1rem;
    }
  }
  </style>
  