<template>
  <nav class="navbar navbar-expand-lg navbar-light fixed-top bg-white shadow-sm" id="mainNav">
    <div class="container px-5">
      <router-link to="/" class="navbar-brand fw-bold" href="#page-top">
        <img src="@/assets/icon/icon.png" alt="yugin-logo" srcset="@/assets/icon/icon.png"> Yugin
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        <i class="fa-solid fa-list"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav ms-auto me-4 my-3 my-lg-0">
          <li class="nav-item"><router-link to="/about" class="nav-link me-lg-3" href="#about">About Us</router-link></li>
          <li class="nav-item"><router-link to="/pricing" class="nav-link me-lg-3" href="#pricing">Pricing</router-link></li>
          <li class="nav-item"><router-link to="/signup" class="nav-link me-lg-3" href="#signup">Sign Up</router-link></li>
          <li class="nav-item">
            <a @click.prevent="handleLoginClick" class="nav-link me-lg-3" href="#login">Login</a>
          </li>
        </ul>
        <a href="http://aarya.yugin.co" class="btn btn-aarya" target="_blank">Aarya AI</a>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: 'AppHeader',
  methods: {
    handleLoginClick() {
      if (this.isUserLoggedIn()) {
        // User is logged in, redirect to My Hub
        this.$router.push('/my-hub');
      } else {
        // User is not logged in, navigate to login page
        this.$router.push('/login');
      }
    },
    isUserLoggedIn() {
      // Check if the user is logged in by looking for a token in localStorage
      return localStorage.getItem('isLoggedIn');
    }
  }
}
</script>
<style scoped>
body, html {
  padding-top: 70px; /* Adjust this value based on the actual height of your navbar */
  margin-bottom: 100px;
}
.navbar {
  background-color: var(--background-color);
  border-bottom: 1px solid var(--accent-color);
}
img {
  height: 35px;
  margin-right: 1px;
}
.navbar-brand {
  font-size: 1.8em;
  color: var(--primary-color);
}
.nav-link {
  color: var(--primary-color);
  transition: all 0.3s ease;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: semibold;
}
.nav-link:hover, .nav-link:focus {
  color: var(--accent-color);
  background-color: var(--primary-color);
  text-decoration: none;
}
.navbar-toggler {
  border-color: var(--primary-color);
}
.btn-primary {
  background-color: var(--primary-color);
  border: none;
}
.btn-primary:hover, .btn-primary:focus {
  background-color: var(--primary-color);
  box-shadow: none;
}
#mainNav {
  padding-bottom: 10px;
}

/* New styles for Aarya AI button */
.btn-aarya {
  background-color: rgb(22 163 74); /* A distinct color for Aarya AI */
  color: white;
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 20px;
  transition: all 0.3s ease;
  text-decoration: none;
  margin-left: 15px;
}

.btn-aarya:hover, .btn-aarya:focus {
  background-color: rgb(22 163 74);
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

@media (max-width: 991px) {
  .btn-aarya {
    margin-top: 10px;
    margin-left: 0;
    display: inline-block;
  }
}
</style>