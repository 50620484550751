<template>
  <aside class="sidebar">
    <div class="logo">
      <img src="@/assets/icon/icon.png" alt="Yugin Drive">
      <span>Yugin Nebula</span>
    </div>
    <nav>
      <ul>
        <li :class="{ active: $route.path === '/my-hub' }">
          <router-link to="/my-hub"><i class="fas fa-th-large"></i> My Hub</router-link>
        </li>
        <li :class="{ active: $route.path === '/edit-profile' }">
          <router-link to="/edit-profile"><i class="fas fa-edit"></i> Edit Profile</router-link>
        </li>
        <li>
          <a href="#" @click.prevent="showCreateGroupModal">
            <i class="fas fa-plus-circle"></i> Create Group
          </a>
        </li>
        <li>
          <a href="#" @click.prevent="showJoinGroupModal">
            <i class="fas fa-user-plus"></i> Join a Group
          </a>
        </li>
      </ul>
    </nav>

    <!-- Groups Section -->
    <div v-if="groups.length > 0" class="groups-section">
      <h3>Groups</h3>
      <ul>
        <li v-for="group in groups" :key="group.uuid_id">
          <router-link :to="{ name: 'GroupContent', params: { id: group.uuid_id } }">
            <i class="fas fa-users"></i> {{ group.name }}
          </router-link>
        </li>
      </ul>
    </div>

    <div id="sidebar-storage" class="storage-usage">
      <p>{{ formattedStorageUsed }} of {{ formattedTotalStorage }} used ({{ storagePercentage }}%)</p>
      <div class="progress mb-2">
        <div class="progress-bar" role="progressbar" :style="{ width: storagePercentage + '%' }" :aria-valuenow="storagePercentage" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
      <button id="get-more-storage" @click="goToPricing" class="btn btn-primary btn-block">
        Get More Storage
      </button>
    </div>

     <div class="sign-out">
      <i class="fas fa-sign-out-alt">
        <button @click="signOut" class="sign-out-btn">Sign Out</button>
      </i>
    </div>


   

    <!-- Create Group Modal -->
    <div class="modal fade" id="createGroupModal" tabindex="-1" aria-labelledby="createGroupModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content safari-fix">
          <div class="modal-header bg-light">
            <h5 class="modal-title" id="createGroupModalLabel">
              <i class="fas fa-users-cog me-2 text-primary"></i>Create New Group
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="groupName" class="form-label">Group Name</label>
              <input 
                v-if="!isSafari"
                type="text" 
                class="form-control" 
                id="groupName" 
                v-model="newGroupName" 
                placeholder="Enter group name" 
                required 
                maxlength="100" 
              >
              <div v-else ref="safariInputWrapper">
                <!-- Safari-specific input will be inserted here -->
              </div>
              <div class="invalid-feedback">{{ nameErrorMessage }}</div>
            </div>
            <div class="mb-3">
              <label for="groupDescription" class="form-label">Description</label>
              <textarea 
                v-if="!isSafari"
                class="form-control" 
                id="groupDescription" 
                v-model="newGroupDescription" 
                rows="3" 
                placeholder="Enter group description"
              ></textarea>
              <div v-else ref="safariTextareaWrapper">
                <!-- Safari-specific textarea will be inserted here -->
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary" @click="createGroup">
              <i class="fas fa-check me-2"></i>Create Group
            </button>
          </div>
        </div>
      </div>
    </div>
 <!-- Join Group Modal -->
    <div class="modal fade" id="acceptInvitationModal" tabindex="-1" aria-labelledby="joinGroupModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content safari-fix">
          <div class="modal-header bg-light">
            <h5 class="modal-title" id="joinGroupModalLabel">
              <i class="fas fa-user-plus me-2 text-primary"></i>Join a Group
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="invitationToken" class="form-label">Invitation Token</label>
              <input 
                type="text" 
                class="form-control" 
                id="invitationToken" 
                v-model="invitationToken" 
                placeholder="Enter 6-digit token" 
                maxlength="6" 
              >
              <div class="invalid-feedback" :class="{ 'd-block': invitationTokenError }">
                {{ invitationTokenError }}
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary" @click="joinGroup">
              <i class="fas fa-check me-2"></i>Join Group
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Floating Message Component -->
    <FloatingMessage :show="showMessage" :message="messageText" :type="messageType" />
  </aside>
</template>

<script>
import axios from '@/csrf/axios';
import FloatingMessage from './FloatingMessage.vue';
import { Modal } from 'bootstrap';

export default {
  name: 'SideBar',
  components: {
    FloatingMessage
  },
  data() {
    return {
      newGroupName: '',
      newGroupDescription: '',
      groups: [],
      showMessage: false,
      messageText: '',
      messageType: 'info',
      nameState: true,
      nameErrorMessage: '',
      createGroupModal: null,
      isSafari: /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
       invitationToken: '',
      invitationTokenError: '',
      storageUsed: 0,
      totalStorage: 'NaN',
    };
  },
   computed: {
     formattedStorageUsed() {
      return this.formatStorage(this.storageUsed);
    },
    formattedTotalStorage() {
      return this.formatStorage(this.totalStorage);
    },
    storagePercentage() {
      return this.totalStorage > 0 ? (this.storageUsed / this.totalStorage * 100).toFixed(1) : 0;
    }
  },
  mounted() {
    this.fetchGroups();
        this.fetchStorageUsage();

    this.createGroupModal = new Modal(document.getElementById('createGroupModal'));
        this.acceptInvitationModal = new Modal(document.getElementById('acceptInvitationModal'));

    if (this.isSafari) {
      this.setupSafariInputs();
    }
  },
  methods: {
    signOut() {
      localStorage.removeItem('authtoken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('isLoggedIn');
      this.$router.push({ name: 'Login' });
    },

    formatStorage(bytes) {
      const MB = 1024 * 1024;
      const GB = MB * 1024;
      
      if (bytes >= GB) {
        return (bytes / GB).toFixed(2) + ' GB';
      } else {
        return (bytes / MB).toFixed(2) + ' MB';
      }
    },
    async fetchStorageUsage() {
      try {
        const response = await axios.get('/storage/', {
          headers: { requiresAuth: true }
        });
        this.storageUsed = response.data.used_space;
        this.totalStorage = response.data.total_space || 'NaN';
      } catch (error) {
        console.error('Failed to fetch storage usage:', error);
        this.showFloatingMessage('Failed to fetch storage usage', 'error');
      }
    },
    
    goToPricing() {
      this.$router.push('/pricing'); // Adjust the route name as needed
    },
    setupSafariInputs() {
      const inputElement = document.createElement('input');
      inputElement.type = 'text';
      inputElement.className = 'form-control';
      inputElement.id = 'groupName';
      inputElement.placeholder = 'Enter group name';
      inputElement.maxLength = 100;
      inputElement.addEventListener('input', (event) => {
        this.newGroupName = event.target.value;
      });

      const textareaElement = document.createElement('textarea');
      textareaElement.className = 'form-control';
      textareaElement.id = 'groupDescription';
      textareaElement.placeholder = 'Enter group description';
      textareaElement.rows = 3;
      textareaElement.addEventListener('input', (event) => {
        this.newGroupDescription = event.target.value;
      });

      this.$nextTick(() => {
        // @ts-ignore
        this.$refs.safariInputWrapper.appendChild(inputElement);
        // @ts-ignore
        this.$refs.safariTextareaWrapper.appendChild(textareaElement);
      });
    },
    showCreateGroupModal() {
      this.createGroupModal.show();
      if (this.isSafari) {
        this.$nextTick(() => {
          const inputElement = document.getElementById('groupName');
          if (inputElement) {
            inputElement.value = this.newGroupName;
            setTimeout(() => {
              inputElement.focus();
            }, 100);
          }
        });
      }
    },
    validateGroupName() {
      const nameRegex = /^[a-zA-Z0-9\s.,!?&()-]+$/;
      
      if (this.newGroupName.length === 0) {
        this.nameState = false;
        this.nameErrorMessage = 'Group name is required.';
      } else if (this.newGroupName.length > 100) {
        this.nameState = false;
        this.nameErrorMessage = 'Group name must be 100 characters or less.';
      } else if (this.newGroupName.includes('.')) {
        this.nameState = false;
        this.nameErrorMessage = 'Group name cannot contain a dot.';
      } else if (!nameRegex.test(this.newGroupName)) {
        this.nameState = false;
        this.nameErrorMessage = 'Group name can only contain letters, numbers, spaces, and common punctuation.';
      } else {
        this.nameState = true;
        this.nameErrorMessage = '';
      }
      return this.nameState;
    },
    async createGroup() {
      if (!this.validateGroupName()) {
        return;
      }
      try {
        const response = await axios.post('/groups/create/', {
          name: this.newGroupName,
          description: this.newGroupDescription || ''
        }, {
          headers: { requiresAuth: true }
        });
        if (response.status === 201 || response.status === 200) {
          this.showFloatingMessage('Group created successfully!', 'success');
          this.resetForm();
          this.fetchGroups();
          this.createGroupModal.hide();
        }
      } catch (error) {
        this.showFloatingMessage('Failed to create group', 'error');
      }
    },
    async fetchGroups() {
      try {
        const response = await axios.get('/groups/list/', {
          headers: { requiresAuth: true }
        });
        this.groups = response.data;
      } catch (error) {
        console.error('Failed to fetch groups:', error);
        this.showFloatingMessage('Failed to fetch groups', 'error');
      }
    },
    resetForm() {
      this.newGroupName = '';
      this.newGroupDescription = '';
      this.nameState = true;
    },
    showFloatingMessage(message, type) {
      this.messageText = message;
      this.messageType = type;
      this.showMessage = true;
      setTimeout(() => {
        this.showMessage = false;
      }, 3000);
    },
     showJoinGroupModal() {
      this.invitationToken = '';
      this.invitationTokenError = '';
      this.acceptInvitationModal.show();
    },

    validateInvitationToken() {
      const tokenRegex = /^[a-zA-Z0-9]{6}$/;
      if (!tokenRegex.test(this.invitationToken)) {
        this.invitationTokenError = 'Token must be 6 alphanumeric characters.';
        return false;
      }
      this.invitationTokenError = '';
      return true;
    },

    async joinGroup() {
      if (!this.validateInvitationToken()) {
        return;
      }

      try {
        const response = await axios.post('/groups/accept/invite/', {
          token: this.invitationToken
        }, {
          headers: { requiresAuth: true }
        });

        if (response.status === 200) {
          this.showFloatingMessage('Successfully joined the group!', 'success');
          this.acceptInvitationModal.hide();
          this.fetchGroups();
        }
      } catch (error) {
        const errorMessage = error.response?.data?.error || 'Failed to join group';
        this.showFloatingMessage(errorMessage, 'error');
      }
    },
    updateGroupList() {
      this.fetchGroups();
    }
  }
};
</script>

<style scoped>

.sidebar {
  width: 250px;
  min-width: 250px;
  background-color: var(--accent-color);
  padding: var(--spacing-medium);
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--hover-color);
  overflow-y: auto; /* Enable scrolling */
  height: 100vh; /* Full height */
}

.logo {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-large);
}

.logo img {
  width: 40px;
  margin-right: var(--spacing-small);
}

nav ul,
.groups-section ul {
  list-style-type: none;
  padding: 0;
}

nav li,
.groups-section li {
  margin-bottom: var(--spacing-medium);
}

nav a,
.groups-section a {
  text-decoration: none;
  color: var(--link-color);
  display: flex;
  align-items: center;
  transition: color 0.3s ease;
}

nav a:hover,
.groups-section a:hover {
  color: var(--primary-color);
}

nav i,
.groups-section i {
  margin-right: var(--spacing-small);
  width: 20px;
  text-align: center;
}

.active {
  font-weight: bold;
}

.sign-out {
  margin-top: auto;
}

.sign-out-btn {
  background: none;
  border: none;
  color: var(--error-color);
  cursor: pointer;
}

.groups-section {
  margin-top: var(--spacing-large);
}

.groups-section h3 {
  font-size: 1.1em;
  margin-bottom: var(--spacing-small);
}

.modal-header .modal-title {
  display: flex;
  align-items: center;
}

.modal-header .modal-title i {
  font-size: 1.2em;
}

.btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--accent-color);
}

.btn-primary:hover {
  background-color: var(--link-color);
  border-color: var(--link-color);
}

.btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  background-color: #5a6268;
  border-color: #545b62;
}

#invitationToken {
  letter-spacing: 3px;
  font-size: 1.2em;
  text-align: center;
}

#acceptInvitationModal .modal-title i {
  color: var(--primary-color);
}

.invalid-feedback {
  display: none;
  color: var(--error-color);
  font-size: 0.875em;
  margin-top: 0.25rem;
}

.invalid-feedback.d-block {
  display: block;
}

#sidebar-storage {
  margin-top: var(--spacing-large);
  padding: var(--spacing-medium);
  background-color: var(--accent-color);
  border-radius: var(--border-radius-medium);
}

#sidebar-storage p {
  margin-bottom: var(--spacing-small);
  font-size: 0.9em;
  color: var(--text-color);
}

#sidebar-storage .progress {
  height: 8px;
  background-color: var(--bg-tertiary);
  border-radius: var(--border-radius-small);
  overflow: hidden;
  border: 1px solid var(--link-color);
}

#sidebar-storage .progress-bar {
  background-color: var(--primary-color);
  transition: width 0.3s ease;
}

#get-more-storage {
  width: 100%;
  margin-top: var(--spacing-small);
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--accent-color);
  transition: background-color 0.3s ease;
}

#get-more-storage:hover {
  background-color: var(--link-color);
  border-color: var(--link-color);
}

/* Safari-specific fixes */
.safari-fix {
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
}

@media not all and (min-resolution: .001dpcm) {
  @supports (-webkit-appearance: none) and (not (translate: none)) {
    .modal-content {
      transform: translateZ(0);
      -webkit-transform: translateZ(0);
      -webkit-backface-visibility: hidden;
      -webkit-perspective: 1000;
    }
  }
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .sidebar {
    width: 200px;
    min-width: 200px;
  }

  .logo img {
    width: 35px;
  }

  nav a,
  .groups-section a {
    font-size: 0.9em;
  }
}

@media (max-width: 768px) {
  .sidebar {
    width: 180px;
    min-width: 180px;
  }

  .logo img {
    width: 30px;
  }

  nav a,
  .groups-section a {
    font-size: 0.85em;
  }

  #sidebar-storage p {
    font-size: 0.85em;
  }
}

@media (max-width: 480px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    border-top: 1px solid var(--hover-color);
    border-right: none;
  }

  .logo {
    display: none; /* Hide logo on very small screens */
  }

  nav {
    display: flex;
    flex-direction: row;
  }

  nav ul,
  .groups-section ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
  }

  nav li,
  .groups-section li {
    margin-bottom: 0;
    margin-right: var(--spacing-small);
  }

  #sidebar-storage {
    display: none; /* Hide storage info on very small screens */
  }

  .sign-out {
    margin-top: 0;
  }
}

</style>
